@use './variables/colors';
@use './breakpoints/breakpoints.scss';

body {
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p, body, span {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none !important;
}


::-webkit-scrollbar {
    width: 0.7em;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    outline: 1px solid #b3b3b3;
}

.global-container {
    padding-left: 10px;
    padding-right: 10px;
}

.container-height {
    margin-top: 20px;
    padding-top: 40px;
    min-height: 76vh;
    margin-bottom: 50px;
    h1 {
        color: colors.$primaryColor;
    }
}

.container-footer {
    width: 100%;
}

.copyright-text {
    text-align: center;
    color: #092c4c;
}

.button-auth {
    margin: 15px 0;
    button {
        cursor: pointer;
        background-color: #43a047;
        border: #fff solid 1px;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 9px;
        padding: 10px 17px;
        width: 100%;
        text-align: center;
        transition: all 0.3s ease;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    :disabled {
        background-color: #ddd;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
        border: none;
        outline: none;
    }
}
.button-auth:hover {
    button {
        cursor: pointer;
        background-color: #138f19;
        border: #fff solid 1px;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 9px;
        padding: 10px 17px;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
    }
    :disabled {
        background-color: #ddd;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
        border: none;
        outline: none;
    }
}

.react-multi-email-input {
    input {
        width: 100% !important;
    }
}


.btn-chat-disabled {
    cursor: pointer;
    font-size: 12px;
    margin-right: 7px;
    justify-content: flex-end;
    border: #4b4b4b 2px solid;
    padding: 6px 10px;
    border-radius: 10px;
    text-align: center;
    // background-color: #8f8f8f;
    p {
        color: #4b4b4b !important;
        font-weight: 400 !important;
    }
}
.container-box-auth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: #f9fafc;
    background-size: cover;
    height: 100vh;
    .paper-box {
        margin: 10px;
        background-color: #fff;
        box-shadow: #ddd 0 0 10px;
        border-radius: 15px;
        width: fit-content;
        height: fit-content;
        padding: 4rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: fit-content;
        img {
            width: 150px;
            margin: 5px 0;
        }
        .name-login {
            text-align: center;
            padding-bottom: 1rem;
            h1 {
                padding-bottom: 5px;
                color: #43a047;
                font-weight: 500;
                font-size: 28px;
            }
            p {
                color: #43a047;
                font-weight: 400;
            }
        }
        .form-auth {
            margin-top: 1rem;
            width: 400px;
            h2 {
                text-align: left;
                font-size: 15px;
                color: #43a047;
                font-weight: 400;
                letter-spacing: 0.5px;
            }
            .button-auth {
                margin: 15px 0;
                button {
                    cursor: pointer;
                    background-color: #43a047;
                    border: #fff solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 9px;
                    padding: 10px 17px;
                    width: 100%;
                    text-align: center;
                    transition: all 0.3s ease;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                :disabled {
                    background-color: #ddd;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                    border: none;
                    outline: none;
                }
            }
            .button-auth:hover {
                button {
                    cursor: pointer;
                    background-color: #138f19;
                    border: #fff solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 9px;
                    padding: 10px 17px;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                    border: none;
                    outline: none;
                }
            }
        }
        @include breakpoints.media('<=phone') {
            .form-auth {
                margin-top: 1rem;
                width: 280px;
            }
            .name-login {
                h1 {
                    font-size: 23px;
                }
                p {
                    font-size: 15px;
                }
            }
        }
        @include breakpoints.media('>phone', '<=tablet') {
            .form-auth {
                margin-top: 1rem;
                width: 280px;
            }
            .name-login {
                h1 {
                    font-size: 24px;
                }
                p {
                    font-size: 15px;
                }
            }
        }
    }
}



.error-p {
    color: #d43232;
    padding-left: 5px;
    padding-top: 0px;
    font-size: 13px;
}

.box-modal-create {
    min-height: 500px;
    margin-bottom: 20px;
    padding: 15px;
}

.box-modal-modules {
    min-height: 500px;
    margin-bottom: 20px;
    padding: 15px;
}

.vendor-store {
    h2 {
        padding-bottom: 5px;
    }
}

#select-style-cat-update {
    z-index: 159 !important;
}


#select-style-countryy {
    z-index: 99 !important;
}


#select-style-provincee {
    z-index: 98 !important;
}

#select-style-cityy {
    z-index: 97 !important;
}



#select-style-subdistrict {
    z-index: 95 !important;
}

#select-style-village {
    z-index: 93 !important;
}
#select-style-zipcode {
    z-index: 90 !important;
}

#select-style-cat {
    z-index: 250 !important;
}

#select-style-type {
    z-index: 240 !important;
}

#select-style-province {
    z-index: 30 !important;
}

#select-style-city {
    z-index: 20 !important;
}

#select-style-district {
    z-index: 10 !important;
}

#select-style-searchby {
    z-index: 50 !important
}

#select-style-value {
    z-index: 50 !important
}

#select-style-delivery {
    z-index: 190  !important
}

#select-style-department {
    z-index: 200  !important
}

.image-gallery-thumbnail {
    height: 70px !important;
    width: 70px !important;
    // border: 2px solid #ddd !important;
    margin-right: 5px;
    cursor: pointer;
    padding: 10px;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 2px solid #ddd !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover {
    border: 2px solid #ddd !important;
}
body {
    font-family: sans-serif;
    // padding: 1em;
  }
  
  .ais-SearchBox {
    margin: 1em 0;
  }
  
  .ais-Pagination {
    margin-top: 1em;
  }
  
  .left-panel {
    float: left;
  }
  
  .right-panel {
    margin-left: 290px;
  }

  .span-linethrought {
    text-decoration: line-through;
    color: #0a449b;
  }

  .hit-name {
    padding-top: 16px;
    h5 {
        font-size: 13px;
        min-height: 50px;
        font-weight: 500;
    }
  }

  .hit-vendor {
      p {
        font-weight: bold;
        font-size: 15px;
        padding-bottom: 5px;
        text-decoration: underline;
      }
  }
  .hit-price {
      font-size: 16px;
      font-weight: 600;
      h3 {
          color: #c70000;
          font-size: 17px;
      }
      h4 {
          font-size: 17px;
      }
  }

  .hit-price-coret {
    font-size: 16px;
    font-weight: 600;
    h3 {
        color: #c70000;
        font-size: 17px;
    }
    h4 {
        text-decoration: line-through;
        font-size: 17px;
        font-weight: 400
    }
}
  
  .hit-description {
    color: #888;
    font-size: 13px;
    padding-bottom: 10px;
    min-height: 55px;
  }

  .ais-SearchBox-input {
      height: 40px;
  }
  

  .badge-discount {
    background-color: red;
    width: fit-content;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    font-size: 13px;
    padding: 2px 4px;
    margin-right: 5px;

  }
  
  .content-search {
    //   margin-top: 10px;
      position: relative;
      .img-product {
          cursor: pointer;
          padding: 5px;
          display: flex;
          justify-content: center;
          img {
              width: 130px;
              height: 130px;
          }
          
      }
      .badge-discount {
        background-color: red;
        width: fit-content;
        color: #fff;
        font-weight: bold;
        border-radius: 4px;
        font-size: 13px;
        padding: 2px 4px;
        margin-right: 5px;

      }
  }

  .ais-ClearRefinements {
      margin-bottom: 15px;
  }

  .button-card {
      padding-top: 10px;
      width: 100%;
  }


  /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    #hover-info {
        padding: 2px 5px;
        cursor: pointer;
        h6 {
            color: #364153;
            font-size: 12px;
            font-weight: 400;
            padding: 3px 0;
        }
        img {
            cursor: pointer;
        }
    }

    .hover-img {
        cursor: pointer;
    }
    #ce-add-username-input {
        display: none !important;
    }

    #ce-options-drop-down {
        display: none !important;
    }
    #ce-delete-chat-button {
        display: none !important;
    }

    #new-chat-plus-button {
        display: none !important;
    }

    .ce-chat-form-container {
        margin-bottom: 35px !important;
    }

    .ais-Hits-item, .ais-InfiniteHits-item, .ais-InfiniteResults-item, .ais-Results-item {
        width: calc(33% - 1rem) !important
    }

    .ais-Hits-list {
        display: grid;
    }

    @media (min-width: 680px) {
        .ais-Hits-list {
            grid-template-columns: 1fr 1fr;
        }
    }
    
    @media (min-width: 900px) {
        .ais-Hits-list {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    
    @media (min-width: 1200px) {
        .ais-Hits-list {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }