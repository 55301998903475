@use '../breakpoints/breakpoints.scss';

.container-auth {
    height: 100vh;
    background-image: url("../../img/bg-auth.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .auth-box {
        background-color: #fff;
        box-shadow: #ddd 0 0 15px;
        border-radius: 15px;
        width: calc(100% - 35%);
        height: calc(100% - 25%);
        position: relative;
        display: flex;
        .left-img {
            background-image: url("../../img/vector-auth.png");
            background-size: cover;
            border-radius: 15px 0 0 15px;
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: center;
            img {
                width: 120px;
                position: absolute;
                bottom: 0;
                margin-bottom: 1rem;
            }
            h1 {
                font-size: 37px;
                font-weight: 500;
                color: #fff;
                position: absolute;
                bottom: 0;
                margin-bottom: calc(100vh - 84vh);
                text-align: center;
            }
            h2 {
                font-size: 17px;
                font-weight: 300;
                color: #fff;
                position: absolute;
                bottom: 0;
                margin-bottom: calc(100vh - 90vh);
                text-align: center;
            }
        }
        .right-content {
            padding: 0 10px;
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            position: relative;
            .form-auth-container {
                h3 {
                    font-size: 26px;
                    font-weight: 500;
                    color: #43a047;
                    padding-bottom: 0.4rem;
                }
                h6 {
                    font-size: 15px;
                    font-weight: 400;
                    color: #000;
                    text-align: center;
                    width: 100%;
                }
                img {
                    width: 40px;
                    margin-bottom: 0.5rem;
                }
                .forgot-text {
                    display: flex;
                    justify-content: flex-end;
                    padding: 5px 0;
                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                        cursor: pointer;
                    }
                }
                width: 100%;
                padding-left: 2.5rem;
                padding-right: 2.5rem;
                .form-group {
                    margin-top: 2rem;
                    width: 100%;
                    h2 {
                        text-align: left;
                        font-size: 15px;
                        color: #000;
                        font-weight: 400;
                        letter-spacing: 0.5px;
                    }
                    .button-auth {
                        margin: 20px 0;
                        button {
                            cursor: pointer;
                            background-color: #43a047;
                            border: #43a047 solid 1px;
                            // box-shadow: #43a047 0px 0px 5px;
                            border-radius: 6px;
                            padding: 10px 17px;
                            width: 100%;
                            text-align: center;
                            transition: all 0.3s ease;
                            color: #fff;
                            font-size: 16px;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        :disabled {
                            background-color: #ddd;
                            color: rgb(168, 165, 165);
                            cursor: not-allowed;
                            border: none;
                            outline: none;
                        }
                    }
                    .button-auth:hover {
                        button {
                            cursor: pointer;
                            background-color: #27832b;
                            border: #27832b solid 1px;
                            box-shadow: #27832b 0px 0px 5px;
                            border-radius: 6px;
                            padding: 10px 17px;
                            width: 100%;
                            text-align: center;
                            color: #fff;
                            font-size: 16px;
                            font-weight: 500;
                        }
                        :disabled {
                            background-color: #ddd;
                            color: rgb(168, 165, 165);
                            cursor: not-allowed;
                            border: none;
                            outline: none;
                        }
                    }
                }
            }
            .footer-auth {
                position:absolute;
                bottom: 0;
                margin-bottom: 2rem;
                p {
                    font-size: 15px;
                    font-weight: 400;
                    color: #000;
                }
                a {
                    color: rgb(1, 31, 163);
                }
            }
        }
    }
    @include breakpoints.media('>phone', '<=tablet') {
        .auth-box {
            width: calc(100% - 10%);
            .left-img {
                display: none;
            }
            .right-content {
                padding: 0 10px;
                width: 100%;
                .form-auth-container {
                    margin: 0 15px;
                    width: 100%;
                    padding-left: 0.1rem;
                    padding-right: 0.1rem;
                }
            }
        }
    }
    @include breakpoints.media('>tablet', '<=desktop') {
        .auth-box {
            width: calc(100% - 20%);
            .left-img {
                padding: 0 10px;
                h1 {
                    font-size: 20px;
                    margin-bottom: calc(100vh - 86vh);
                }
                h2 {
                    font-size: 10px;
                    margin-bottom: calc(100vh - 90vh);
                }
            }
            .right-content {
                padding: 0 10px;
                width: 100%;
                .form-auth-container {
                    margin: 0 15px;
                    width: 100%;
                    padding-left: 0.1rem;
                    padding-right: 0.1rem;
                }
            }
        }
    }
}