
@use '../breakpoints/breakpoints.scss';


.order-container {
    height: calc(100vh - 150px);
}
 
@include breakpoints.media('<=phone') {
    .order-container {
        height: max-content;
    }
}
@include breakpoints.media('>phone', '<=896px') {
    .order-container {
        height: max-content;
    }
}

#scrollableDivOrder {
    height: calc(100vh - 50px);
    overflow-x: auto;
}

.container-search {
    margin-top: 10px;
    background-color: #fff;
    border: #ddd solid 1px;
    box-shadow: #ddd 0px 0px 15px;
    border-radius: 10px;
    // height: 150px;
    // position: fixed;
    padding: 15px;
    .status {
        display: flex; 
        flex-direction: row;
        align-items: center;
        padding-top: 15px;
        .headline-status {
            display: flex;
            flex-direction: row;
            align-items: center;
            h5 {
                font-size: 15px;
                font-weight: 500;
                color: #000;
                text-transform: capitalize;
            }
            padding-right: 10px;
            padding-bottom: 10px;
            .icon-arrow {
                display: none;
            }
        }
        .list-status {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 0.5rem;
            row-gap: 0.5rem;
            .container-status {
                position:relative;
                .status-box {
                    border: #ddd solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    h6 {
                        font-size: 17px;
                        font-weight: 400;
                        color: #092c4c;
                        text-transform: capitalize;
                        margin: 0;
                    }
                }
                @include breakpoints.media('>phone', '<=tablet') {
                    .status-box {
                        h6 {
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                }
                .active {
                    background-color: #43a047;
                    h6 {
                        color: #fff;
                        margin: 0;
                    }
                }
            }
            .notify-badge{
                position: absolute;
                right:-7px;
                top:-10px;
                background:#c70000;
                text-align: center;
                border-radius: 50%;
                color:white;
                padding: 3px 4px;
                min-width: 14px;
                height: fit-content;
                font-size:12px;
                z-index: 1000;
                font-family: 'Roboto', sans-serif;
            }
        }
    }
    @include breakpoints.media('<=tablet') {
        .status {
            display: flex; 
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .headline-status {
                padding-right: 10px;
                .icon-arrow {
                    display: block;
                    padding-top: 4px;
                    cursor: pointer;
                }
            }
        }
    }
    .status-package {
        margin: 10px 0;
        display: flex; 
        flex-direction: row;
        align-items: center;
        .list-status {
            display: flex;
            flex-wrap: wrap;
            column-gap: 1rem;
            background-color: #f4f4f4c0;
            border-radius: 7px;
            padding: 10px;
            .container-status {
                position:relative;
                display:inline-block;
                margin-top: 8px;
                .status-box {
                    // background-color: #fff;
                    border: #ddd solid 2px;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    h6 {
                        font-size: 14px;
                        font-weight: 400;
                        color: #092c4c;
                        text-transform: capitalize;
                        margin: 0;
                    }
                }
                @include breakpoints.media('>phone', '<=tablet') {
                    .status-box {
                        h6 {
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                }
                .active {
                    border: #43a047 2px solid;
                    h6 {
                        color: #43a047;
                        font-size: 14px;
                        margin: 0;
                    }
                }
            }
            .notify-badge{
                position: absolute;
                right:-13px;
                top:-9px;
                background:#43a047;
                // border: 1px solid #43a047;
                text-align: center;
                border-radius: 50%;
                color:white;
                padding: 3px 4px;
                min-width: 13px;
                height: fit-content;
                font-size:12px;
                z-index: 1000;
                font-family: 'Roboto', sans-serif;
            }
        }
    }
    .search-box {
        display: flex;
        align-items: center;;
        background-color: #fff;
        border: #ddd solid 2px;
        border-radius: 10px;
        padding: 0px 10px;
        input {
            background-color: #fff;
            padding: 13.5px 10px;
            width: 100%;
            border: none;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 300;
            color: #000;
            &:focus {
                outline: none;
            }
        }
        #select-style-addresss {
            width: 150px;
            border-style: none !important;
        }
        .notyping {
            display: flex;
            align-items: center;
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        .typing {
            display: flex;
            align-items: center;
            .removeimg {
                width: 17px;
                height: 17px;
                cursor: pointer;
            }
            .divider-line {
                width: 1px;
                height: 20px;
                background-color: #000;
                margin: 0 15px;
            }
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        
    }
    .recently-view {
        padding: 13px 10px;
        display: flex;
        border-radius: 10px;
        cursor: pointer;
        justify-content: center;
        background-color: #fff;
        border: #ddd solid 2px;
        img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }
    .mobile-status {
        border: #43a047 1px solid;
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        margin: 8px 0;
        padding: 10px;
        h5 {
            font-size: 15px;
            font-weight: 500;
            color: #43a047;
            text-transform: capitalize;
            letter-spacing: 0.5px;
        }
    }
}


.loading-container {
    margin: 5px 10px;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 5px;
    padding: 20px;
}

.disable-item {
    // background-color: #ffbc01  !important;
    box-shadow: #43a047 0px 0px 5px !important;
    .right-div-disabled {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        row-gap: 0.5rem;
        .view-btn {
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 6px;
            p {
                font-size: 14px;
                color: #43a047;
                font-weight: 500;
            }
        }
        .view-btn-confirm {
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 6px;
            p {
                font-size: 14px;
                color: #ff9f43;
                font-weight: 500;
            }
        }
        .view-btn-status {
            cursor: pointer;
            background-color: #43a047; 
            padding: 5px 15px;
            border-radius: 20px;
            p {
                font-size: 14px;
                color: #fff;
                font-weight: 400;
            }
        }
        .view-btn-status-outlined {
            cursor: pointer;
            border: 1px solid #43a047; 
            padding: 5px 15px;
            border-radius: 20px;
            p {
                font-size: 14px;
                color: #43a047;
                font-weight: 400;
            }
        }
        .view-btn-status-confirm {
            background-color: #ff9f43; 
            padding: 5px 15px;
            border-radius: 20px;
            p {
                font-size: 14px;
                color: #fff;
                font-weight: 400;
            }
        }
    }
}

.container-item-orders {
    margin: 15px 2px;
    padding: 20px;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    // height: 150px;
    // width: 100%;
    .top-div {
        background-color: #fff;
        box-shadow: #ddd 0px 2px 0px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .left {
            padding-bottom: 5px;
            // display: flex;
            flex-wrap: wrap;
            // align-items: center;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
                padding-top: 3px;
            }
            h6 {
                font-size: 15px;
                color: #000;
                font-weight: bold;
            }
            h5 {
                font-size: 15px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                padding-bottom: 5px;
            }
            .line-right {
                border-right: #ddd 1px solid;
                margin: 0 10px;
            }
            .package-detail {
                margin-top: 2px;
                display: flex;
                // align-items: center;
                padding-top: 5px;
                p{
                    padding-top: 3px;
                }
                .line-rightt {
                    border-right: #ddd 1px solid;
                    margin: 3px 10px;
                }
                .history {
                    cursor: pointer;
                    padding-top: 5px;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            padding-bottom: 5px;
            .status {
                padding: 3px 10px;
                border-radius: 5px;
                h6 {
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                }
            }
            img {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-right: 5px;
            }
            .container-btn-item {
                display: flex;
                align-items: center;
                padding-bottom: 5px;
                margin: 0 5px;
                flex-wrap: wrap;
                .print-btn {
                    margin: 0 15px;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    p {
                        font-size: 14px;
                        color: #43a047;
                        font-weight: 400;
                    }
                }
                .btn-pr-submit {
                    margin: 5px;
                    button {
                        cursor: pointer;
                        background-color: #fff;
                        border: #43a047 solid 1px;
                        box-shadow: #ddd 0px 0px 10px;
                        border-radius: 5px;
                        padding: 5px 15px;
                        transition: all 0.3s ease;
                        width: 100%;
                        text-align: center;
                        color: #43a047;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    :disabled {
                        background-color: #ddd;
                        border: #ddd solid 1px;
                        color: rgb(168, 165, 165);
                        cursor: not-allowed;
                    }
                }
                .btn-pr-submit:hover {
                    button {
                        cursor: pointer;
                        background-color: #43a047;
                        border: #fff solid 1px;
                        box-shadow: #ddd 0px 0px 10px;
                        border-radius: 5px;
                        padding: 5px 15px;
                        width: 100%;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    :disabled {
                        background-color: #ddd;
                        color: rgb(168, 165, 165);
                        cursor: not-allowed;
                    }
                }
                .btn-pr-reject {
                    button {
                        cursor: pointer;
                        background-color: #fff;
                        border: #e8505b solid 1px;
                        box-shadow: #ddd 0px 0px 10px;
                        border-radius: 5px;
                        padding: 5px 15px;
                        transition: all 0.3s ease;
                        width: 100%;
                        text-align: center;
                        color: #e8505b;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    :disabled {
                        background-color: #ddd;
                        border: #ddd solid 1px;
                        color: rgb(168, 165, 165);
                        cursor: not-allowed;
                    }
                }
                .btn-pr-reject:hover {
                    button {
                        cursor: pointer;
                        background-color: #e8505b;
                        border: #fff solid 1px;
                        box-shadow: #ddd 0px 0px 10px;
                        border-radius: 5px;
                        padding: 5px 15px;
                        width: 100%;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    :disabled {
                        background-color: #ddd;
                        color: rgb(168, 165, 165);
                        cursor: not-allowed;
                    }
                }
                .btn-item-submit {
                    margin: 0 5px;
                    button {
                        cursor: pointer;
                        background-color: #3bb001;
                        border: #fff solid 1px;
                        box-shadow: #ddd 0px 0px 10px;
                        border-radius: 5px;
                        padding: 5px 15px;
                        width: 100%;
                        text-align: center;
                        transition: all 0.3s ease;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    :disabled {
                        background-color: #ddd;
                        color: rgb(168, 165, 165);
                        cursor: not-allowed;
                    }
                }
                .btn-item-submit:hover {
                    margin: 0 5px;
                    button {
                        cursor: pointer;
                        background-color: #368f09;
                        border: #fff solid 1px;
                        box-shadow: #ddd 0px 0px 10px;
                        border-radius: 5px;
                        padding: 5px 15px;
                        width: 100%;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    :disabled {
                        background-color: #ddd;
                        color: rgb(168, 165, 165);
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
    .center-div {
        padding: 2px 0;
        align-items: center;
        .left-box {
            .store {
                display: flex;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                    padding: 4px;
                    background-color: #ddd;
                    border-radius: 50%;
                }
                .name {
                    padding-left: 10px;
                    p {
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        color: #364153;
                    }
                    h2 {
                        color: #364153;
                        font-size: 17px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                        padding-bottom: 3px;
                    }
                    .chat {
                        display: flex;
                        align-items: center;
                        .btn-chat {
                            cursor: pointer;
                            font-size: 12px;
                            margin-right: 7px;
                            justify-content: flex-end;
                            border: #43a047 2px solid;
                            padding: 2px 10px;
                            border-radius: 10px;
                            text-align: center;
                            color: #43a047;
                            -moz-transition: all .2s ease-in;
                            -o-transition: all .2s ease-in;
                            -webkit-transition: all .2s ease-in;
                            transition: all .2s ease-in;
                        }
                        .btn-chat:hover {
                            background-color: #1e88b9;
                            border: #1e88b9 2px solid;
                            p {
                                color: #fff;
                            }
                        }
                        p {
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            color: #43a047;
                        }
                    }
                }
            }
            .address {
                padding: 10px 0;
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #364153;
                    text-transform: capitalize;
                }
                h2 {
                    color: #364153;
                    font-size: 17px;
                    font-weight: 500;
                }
            }
           
            .vendor-list {
                padding: 7px 0;
                display: flex;
                flex-wrap: wrap;
                .box-vendor {
                    border: #ddd 1px solid;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 10px;
                    margin: 5px;
                    border-radius: 5px;
                    h3 {
                        text-transform: capitalize;
                        font-size: 15px;
                        color: #000;
                        font-weight: 400;
                    }
                }
                
            }
        }
        .total-prices {
            // border-left: #ddd solid 1px;
            display: flex;
            flex-direction: row;
            margin: 3px 0;
            padding: 15px;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 300;
                padding-bottom: 5px;
            }
            h3 {
                font-size: 17px;
                color: #000;
                font-weight: bold;
                margin: 0;
            }
        }
        .content-right {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // justify-content: flex-end;
            .content {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    font-size: 14px;
                    color: #000;
                    font-weight: 300;
                }
                h3 {
                    font-size: 16px;
                    color: #000;
                    font-weight: 500;
                    margin: 0;
                }
                h2 {
                    font-size: 18px;
                    color: #000;
                    font-weight: bold;
                    margin: 0;
                }
            }
            .linedivder {
                border-bottom: #ddd 1px solid;
                margin: 10px 0;
            }
        }
    }
    .bottom-div {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;        
        align-items: center;
        flex-wrap: wrap;
        .left-div {
            .list-status {
                display: flex;
                flex-wrap: wrap;
                .container-status {
                    position:relative;
                    display:inline-block;
                    margin: 5px;
                    .status-box {
                        // background-color: #fff;
                        display: flex;
                        align-items: center;
                        border: #ddd solid 1px;
                        box-shadow: #ddd 0px 0px 10px;
                        padding: 5px 10px;
                        border-radius: 15px;
                        cursor: pointer;
                        h6 {
                            font-size: 12px;
                            font-weight: 400;
                            color: #afc0c9;
                            text-transform: capitalize;
                            margin: 0;
                        }
                        h3 {
                            font-size: 12px;
                            font-weight: 400;
                            color: #368f09;
                            text-transform: capitalize;
                            margin: 0;
                        }
                        h5 {
                            font-size: 16px;
                            font-weight: bold;
                            color: #368f09;
                            padding-right: 5px;
                            margin: 0;
                        }
                        h4 {
                            font-size: 16px;
                            font-weight: 500;
                            color: #afc0c9;
                            padding-right: 5px;
                            margin: 0;
                        }
                    }
                    @include breakpoints.media('>phone', '<=tablet') {
                        .status-box {
                            display: flex;
                            h6 {
                                font-size: 13px;
                                font-weight: 400;
                            }
                            h5 {
                                font-size: 12px;
                                font-weight: 400;
                                color: #368f09;
                            }
                        }
                    }
                    .active {
                        background-color: #43a047;
                        h6 {
                            color: #fff;
                            margin: 0;
                        }
                    }
                }
                .notify-badge{
                    position: absolute;
                    right:-10px;
                    top:-14px;
                    background:#c70000;
                    text-align: center;
                    border-radius: 50%;
                    color:white;
                    padding: 3px 4px;
                    min-width: 15px;
                    height: fit-content;
                    font-size:12px;
                    z-index: 1000;
                    font-family: 'Roboto', sans-serif;
                }
            }
        }
        .right-div {
            display: flex;
            align-items: center;
            .view-btn {
                display: flex;
                cursor: pointer;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                }
                p {
                    font-size: 15px;
                    color: #43a047;
                    font-weight: bold;
                }
            }
            .line-center {
                border-left: #ddd solid 1px;
                height: 20px;
                margin: 0px 10px;
            }
            .print-btn {
                display: flex;
                cursor: pointer;
                align-items: center;
                border: #43a047 1px solid;
                transition: all .2s ease-in;
                border-radius: 10px;
                padding: 5px 10px;
                p {
                    font-size: 14px;
                    color: #43a047;
                    font-weight: 400;
                }
            }
            .print-btn:hover{
                display: flex;
                cursor: pointer;
                align-items: center;
                background-color: #43a047;
                border-radius: 10px;
                padding: 5px 10px;
                p {
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                }
            }
        }
    }
}

.card-product-items {
    margin: 15px 0;
    // box-shadow: #ddd 0px 0px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    

    .container-btn-submit {
        margin: 0 5px;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 5px;
        .btn-item-submit {
            button {
                cursor: pointer;
                background-color: #3bb001;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 7px 17px;
                width: 100%;
                text-align: center;
                transition: all 0.3s ease;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-item-submit:hover {
            button {
                cursor: pointer;
                background-color: #368f09;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 7px 17px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
    }

    .wrapper-item {
        max-height: 300px;
        overflow-y: auto;
    }
    .active-update {
        box-shadow: #43a047 0px 0px 5px !important;
    }
    
    .item-product {
        display: flex;
        box-shadow: #ddd 0px 0px 5px;
        margin: 10px 5px;
        border-radius: 10px;
        padding: 10px;
        img {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            background-color: #fff;
        }
        .right-product {
            width: 100%;
            margin: 0 15px;
            .title-product {
                h2 {
                    font-size: 15px;
                    font-weight: 400;
                    color: #364153;
                }
            }
            .bottom-content {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-top: 5px;
                .left-box {
                    .input-note {
                        input {
                            width: 150%;
                            padding: 0 5px;
                            height: 30px;
                            border-radius: 5px;
                            outline: none;
                            border: 1px solid #1b4559;
                        }
                    }
                }
                .right-box {
                    display: flex;
                    justify-content: flex-end;
                    .total-tax {
                        border-radius: 5px;
                        height: fit-content;
                        margin-top: 3px;
                        h2 {
                            font-size: 13px;
                            font-weight: 300;
                            color: #1b4559;
                        }
                    }
                    .total-qty {
                        h2 {
                            font-size: 14px;
                            font-weight: 500;
                            color: #1b4559;
                        }
                    }
                    .total-prices {
                        border-radius: 5px;
                        height: fit-content;
                        h2 {
                            font-size: 16px;
                            font-weight: bold;
                            color: #1b4559;
                        }
                    }
                    .line-divide {
                        margin: 0 10px;
                        border-left: #ddd solid 1px;
                    }
                    .quantity-btn {
                        // padding-top: 3px;
                        display: flex;
                        // align-items: center;
                        .minus-btn {
                            img {
                                cursor: pointer;
                                width: 23px;
                                height: 23px;
                            }
                        }
                        .input-qty {
                            input {
                                margin: 0 5px;
                                width: 50px;
                                text-align: center;
                                border-radius: 8px;
                                // height: 20px;
                                border: #000 solid 2px;
                            }
                        }
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
            .price {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .discount {
                    background-color: #e8505b;
                    border-radius: 5px;
                    padding: 1px 5px;
                    h3 {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 300;
                    }
                }
                .discount-amount {
                    background-color: #eaa24b;
                    border-radius: 5px;
                    padding: 2px 5px;
                    h3 {
                        color: #fff;
                        font-size: 11px;
                        font-weight: 300;
                    }
                }
                .title-price {
                    h1 {
                        color: #364153;
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
                .title-discount {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    margin-right: 5px;
                    h2 {
                        color: #364153;
                        font-size: 14px;
                        font-weight: 300;
                        padding-right: 5px;
                        text-decoration: line-through;
                    }
                }
                .input-price {
                    border: #364153 solid 1px;
                    border-radius: 5px;
                    display: flex;
                    input {
                        width: 100px;
                        padding: 0 5px;
                        height: 22px;
                        border-radius: 5px;
                        outline: none;
                        border: none;
                    }
                    p {
                        padding-left: 7px;
                        color: #000;
                        font-weight: bold;
                        font-size: 15px;
                    }
                }
                .input-price-discount {
                    border: #364153 solid 1px;
                    border-radius: 5px;
                    margin: 0 5px;
                    display: flex;
                    input {
                        width: 100px;
                        padding: 0 5px;
                        height: 22px;
                        border-radius: 5px;
                        outline: none;
                        border: none;
                    }
                    p {
                        padding-left: 7px;
                        color: #000;
                        font-weight: bold;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

.detail-order-title {
    h2 {
        font-size: 20px;
        font-weight: 500;
        color: #121b2b;
        text-align: center;
    }
    h3 {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }
    @include breakpoints.media('<=tablet') {
        h2 {
            font-size: 15px;
            text-align: center;
            padding: 0 5px;
        }
        h3 {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
    }
}


.detail-packages {
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .height-items {
        max-height: calc(100vh - 250px);
        overflow-y: auto;
    }
    .bttm-content {
        padding-top: 10px;
        margin: 0 7px;
        display: flex;
        flex-wrap: wrap;
        .content {
            justify-content: flex-end;
            width: 100%;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            p {
                font-size: 13px;
                color: #023047;
                font-weight: 300;
                padding-right: 5px;
            }
            h3 {
                font-size: 14px;
                color: #023047;
                font-weight: bold;
                margin: 0;
            }
        }
        .content-shipping {
            justify-content: flex-end;
            width: 100%;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            p {
                font-size: 13px;
                color: #023047;
                font-weight: 300;
                padding-right: 5px;
            }
            h3 {
                font-size: 14px;
                color: #023047;
                font-weight: bold;
                margin: 0;
            }
        }
        
        .linedivder {
            border-bottom: #ddd 1px solid;
            margin: 10px 0;
        }
    }
    .linethrought-bttm {
        .content {
            h3 {
                background-color: #ddd;
                padding: 2px 5px;
                border-radius: 5px;
                font-weight: 400 !important;
                text-decoration: line-through;
            }
        }
    }
    .right-submit-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        .btn-pr-submit {
            button {
                cursor: pointer;
                background-color: #43a047;
                border: #43a047 solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 5px 15px;
                transition: all 0.3s ease;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                border: #ddd solid 1px;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-submit:hover {
            button {
                cursor: pointer;
                background-color: #18661c;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 5px 15px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
    }
    .status-package {
        display: flex; 
        .list-status {
            display: flex;
            flex-wrap: wrap;
            row-gap: 0.5rem;
            column-gap: 0.6rem;
            .container-status {
                position:relative;
                display:inline-block;
                .status-box {
                    border: #ddd solid 2px;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    h6 {
                        font-size: 14px;
                        font-weight: 400;
                        color: #092c4c;
                        text-transform: capitalize;
                        margin: 0;
                    }
                }
                @include breakpoints.media('>phone', '<=tablet') {
                    .status-box {
                        h6 {
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                }
                .active {
                    // border: #43a047 2px solid;
                    background:#43a047;
                    h6 {
                        color: #fff;
                        font-size: 14px;
                        margin: 0;
                    }
                }
            }
            .notify-badge{
                position: absolute;
                right:-13px;
                top:-9px;
                background:#c70000;
                // border: 1px solid #43a047;
                text-align: center;
                border-radius: 50%;
                color:white;
                padding: 3px 4px;
                min-width: 13px;
                height: fit-content;
                font-size:12px;
                z-index: 1000;
                font-family: 'Roboto', sans-serif;
            }
        }
    }
    
    .card-packages {
        width: 100%;
        .approval {
            box-shadow: red 0px 0px 3px !important;
        }
        .canceled {
            box-shadow: red 0px 0px 3px !important;
        }
        .item-product {
            display: flex;
            box-shadow: green 0px 0px 3px;
            margin: 10px 5px;
            border-radius: 10px;
            padding: 10px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 10px;
                background-color: #fff;
            }
            .right-product {
                width: 100%;
                margin: 0 15px;
                .title-product {
                    h2 {
                        font-size: 15px;
                        font-weight: 400;
                        color: #364153;
                    }
                }
                .inbound {
                    .total-prices {
                        background-color: #ddd;
                        padding: 4px;
                        border-radius: 5px;
                        h2 {
                            font-size: 14px !important;
                            font-weight: 400 !important;
                            // color: #000 !important;
                            text-decoration: line-through;
                        }
                    } 
                    .total-qty {
                        background-color: #ddd;
                        padding: 4px;
                        border-radius: 5px;
                        h2 {
                            font-size: 14px !important;
                            font-weight: 400 !important;
                            // color: #000 !important;
                            text-decoration: line-through;
                        }
                    }
                    .total-tax {
                        margin-top: 0!important;
                        background-color: #ddd;
                        padding: 4px;
                        border-radius: 5px;
                        h2 {
                            font-size: 13px !important;
                            font-weight: 400 !important;
                            // color: #000 !important;
                            text-decoration: line-through;
                        }
                    }
                    .discount-amount {
                        background-color: #eaa24b;
                        border-radius: 5px;
                        padding: 2px 5px;
                        h3 {
                            color: #fff;
                            font-size: 11px;
                            font-weight: 300;
                        }
                    }
                    .title-price {
                        background-color: #ddd;
                        padding: 4px;
                        border-radius: 5px;
                        h1 {
                            color: #364153;
                            font-size: 14px !important;
                            font-weight: 400 !important;
                            text-decoration: line-through;
                        }
                    }
                    .title-discount {
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                        margin-right: 5px;
                        background-color: #ddd;
                        padding: 4px;
                        border-radius: 5px;
                        h2 {
                            color: #364153;
                            font-size: 12px !important;
                            font-weight: 300;
                            padding-right: 5px;
                            text-decoration: line-through;
                        }
                    }
                }
                .bottom-content {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding-top: 5px;
                    .left-box {
                        .input-note {
                            input {
                                width: 150%;
                                padding: 0 5px;
                                height: 30px;
                                border-radius: 5px;
                                outline: none;
                                border: 1px solid #1b4559;
                            }
                        }
                        @include breakpoints.media('<=tablet') {
                            .input-note {
                                input {
                                    margin-bottom: 5px;
                                    width: 100%;
                                    padding: 0 5px;
                                    height: 30px;
                                    border-radius: 5px;
                                    outline: none;
                                    border: 1px solid #1b4559;
                                }
                            }
                        }
                    }
                    .right-box {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        .total-tax {
                            border-radius: 5px;
                            height: fit-content;
                            margin-top: 3px;
                            h2 {
                                font-size: 13px;
                                font-weight: 300;
                                color: #1b4559;
                            }
                        }
                        .total-qty-linethrough {
                            text-decoration: line-through;
                        }
                        .total-qty {
                            h2 {
                                font-size: 14px;
                                font-weight: 500;
                                color: #1b4559;
                            }
                        }
                        .total-qty-received {
                            h2 {
                                font-size: 14px;
                                font-weight: 500;
                                color: green;
                            }
                        }
                        .total-prices {
                            border-radius: 5px;
                            height: fit-content;
                            h2 {
                                font-size: 16px;
                                font-weight: bold;
                                color: #1b4559;
                            }
                        }
                        .total-prices-linethrough {
                            text-decoration: line-through;
                        }
                        .line-divide {
                            margin: 0 10px;
                            border-left: #ddd solid 1px;
                        }
                        .quantity-btn {
                            // padding-top: 3px;
                            display: flex;
                            // align-items: center;
                            .minus-btn {
                                img {
                                    cursor: pointer;
                                    width: 23px;
                                    height: 23px;
                                }
                            }
                            .input-qty {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                input {
                                    margin: 0 5px;
                                    width: 50px;
                                    text-align: center;
                                    border-radius: 8px;
                                    // height: 20px;
                                    border: #000 solid 2px;
                                }
                                p {
                                    font-size: 13px;
                                    margin-bottom: 3px;
                                }
                            }
                        }
                        @include breakpoints.media('<=tablet') {
                            .quantity-btn {
                                margin: 5px 0;
                            }
                        }
                        .print-btn-approve {
                            button {
                                cursor: pointer;
                                background-color: #fff;
                                border: #43a047 solid 1px;
                                box-shadow: #ddd 0px 0px 10px;
                                border-radius: 5px;
                                padding: 3px 10px;
                                transition: all 0.3s ease;
                                width: 100%;
                                text-align: center;
                                color: #43a047;
                                font-size: 10px;
                                font-weight: 400;
                            }
                            :disabled {
                                background-color: #ddd;
                                border: #ddd solid 1px;
                                color: rgb(168, 165, 165);
                                cursor: not-allowed;
                            }
                        }
                        .print-btn-approve:hover {
                            button {
                                cursor: pointer;
                                background-color: #43a047;
                                border: #fff solid 1px;
                                box-shadow: #ddd 0px 0px 10px;
                                border-radius: 5px;
                                padding: 3px 10px;
                                width: 100%;
                                text-align: center;
                                color: #fff;
                                font-size: 10px;
                                font-weight: 400;
                            }
                            :disabled {
                                background-color: #ddd;
                                color: rgb(168, 165, 165);
                                cursor: not-allowed;
                            }
                        }
                        .print-btn-reject {
                            button {
                                cursor: pointer;
                                background-color: #fff;
                                border: #e8505b solid 1px;
                                box-shadow: #ddd 0px 0px 10px;
                                border-radius: 5px;
                                padding: 3px 10px;
                                transition: all 0.3s ease;
                                width: 100%;
                                text-align: center;
                                color: #e8505b;
                                font-size: 11px;
                                font-weight: 400;
                            }
                            :disabled {
                                background-color: #ddd;
                                border: #ddd solid 1px;
                                color: rgb(168, 165, 165);
                                cursor: not-allowed;
                            }
                        }
                        .print-btn-reject:hover {
                            button {
                                cursor: pointer;
                                background-color: #e8505b;
                                border: #fff solid 1px;
                                box-shadow: #ddd 0px 0px 10px;
                                border-radius: 5px;
                                padding: 3px 10px;
                                width: 100%;
                                text-align: center;
                                color: #fff;
                                font-size: 11px;
                                font-weight: 400;
                            }
                            :disabled {
                                background-color: #ddd;
                                color: rgb(168, 165, 165);
                                cursor: not-allowed;
                            }
                        }
                        .print-btn-note {
                            button {
                                cursor: pointer;
                                border: none;
                                border-radius: 5px;
                                padding: 3px 0;
                                width: 100%;
                                text-align: center;
                                color: #1b4559;
                                font-size: 11px;
                                font-weight: 400;
                                outline: none;
                                background-color: transparent;
                            }
                        }
                        .print-btn-note:hover {
                            button {
                                cursor: pointer;
                                border: none;
                                border-radius: 5px;
                                padding: 3px 0;
                                width: 100%;
                                text-align: center;
                                color: #0d2029;
                                font-size: 11px;
                                font-weight: 400;
                                outline: none;
                                background-color: transparent;
                            }
                        }
                        .received-status {
                            padding: 0 5px;
                            p {
                                font-size: 14px;
                                font-weight: 400;
                                color: green;
                            }
                        }
                    }
                }
                .price {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    .discount {
                        background-color: #e8505b;
                        border-radius: 5px;
                        padding: 1px 5px;
                        h3 {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                    .discounts {
                        width: 25px;
                    }
                    .discount-amount {
                        background-color: #eaa24b;
                        border-radius: 5px;
                        padding: 2px 5px;
                        h3 {
                            color: #fff;
                            font-size: 11px;
                            font-weight: 300;
                        }
                    }
                    .title-price {
                        h1 {
                            color: #364153;
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }
                    .title-discount {
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                        margin-right: 5px;
                        h2 {
                            color: #364153;
                            font-size: 14px;
                            font-weight: 300;
                            padding-right: 5px;
                            text-decoration: line-through;
                        }
                    }
                    .input-price {
                        border: #364153 solid 1px;
                        border-radius: 5px;
                        display: flex;
                        input {
                            width: 100px;
                            padding: 0 5px;
                            height: 22px;
                            border-radius: 5px;
                            outline: none;
                            border: none;
                        }
                        p {
                            padding-left: 7px;
                            color: #000;
                            font-weight: bold;
                            font-size: 15px;
                        }
                    }
                    .input-price-discount {
                        border: #364153 solid 1px;
                        border-radius: 5px;
                        margin: 0 5px;
                        display: flex;
                        input {
                            width: 100px;
                            padding: 0 5px;
                            height: 22px;
                            border-radius: 5px;
                            outline: none;
                            border: none;
                        }
                        p {
                            padding-left: 7px;
                            color: #000;
                            font-weight: bold;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}