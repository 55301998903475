
@use '../breakpoints/breakpoints.scss';


.card-paper {
    background-color: #fff;
    border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 15px;
    
    .card-info {
        h4 {
            font-weight: 400;
            color: #000;
            font-size: 14px;
        }
        h3 {
            font-weight: 500;
            font-size: 20px;
            color: #43a047;
        }
        img {
            width: 40px;
            height: 40px;
        }
        display: flex;
        justify-content: space-between;
    }
}


.container-search-preferred {
    margin-top: 10px;
    background-color: #fff;
    border: #ddd solid 1px;
    box-shadow: #ddd 0px 0px 15px;
    border-radius: 10px;
    padding: 15px;
    .status {
        display: flex; 
        flex-direction: row;
        align-items: center;
        padding-top: 15px;
        h5 {
            font-size: 15px;
            font-weight: 500;
            color: #000;
            text-transform: capitalize;
        }
        .list-status {
            padding-left: 10px;
            display: flex;
            flex-wrap: wrap;
            .container-status {
                position:relative;
                padding-top:10px;
                display:inline-block;
                margin: 5px 10px;
                .status-box {
                    // background-color: #fff;
                    border: #ddd solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 15px;
                    border-radius: 10px;
                    cursor: pointer;
                    h6 {
                        font-size: 16px;
                        font-weight: 400;
                        color: #092c4c;
                        text-transform: capitalize;
                        margin: 0;
                    }
                }
                @include breakpoints.media('>phone', '<=tablet') {
                    .status-box {
                        h6 {
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                }
                .active {
                    background-color: #43a047;
                    h6 {
                        color: #fff;
                        margin: 0;
                    }
                }
            }
        }
    }
    .search-box {
        display: flex;
        align-items: center;;
        background-color: #fff;
        border: #ddd solid 2px;
        border-radius: 10px;
        padding: 0px 10px;
        input {
            background-color: #fff;
            padding: 10px 10px;
            width: 100%;
            border: none;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 300;
            color: #000;
            &:focus {
                outline: none;
            }
        }
        #select-style-addresss {
            width: 150px;
            border-style: none !important;
        }
        .notyping {
            display: flex;
            align-items: center;
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        .typing {
            display: flex;
            align-items: center;
            .removeimg {
                width: 17px;
                height: 17px;
                cursor: pointer;
            }
            .divider-line {
                width: 1px;
                height: 20px;
                background-color: #000;
                margin: 0 15px;
            }
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        
    }
    .date {
        padding: 6px 10px;
        display: flex;
        border-radius: 10px;
        cursor: pointer;
        justify-content: center;
        background-color: #fff;
        border: #ddd solid 2px;
        img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }
}

.requested {
    background-color: #ccc !important;
    box-shadow: #ddd 0px 0px 10px !important;
    .top-div {
        background-color: #ccc !important;
        box-shadow: #ddd 0px 2px 0px !important;
    }
}

.container-item-preferred {
    margin: 15px 3px;
    padding: 20px;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    .top-div {
        background-color: #fff;
        box-shadow: #ddd 0px 2px 0px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .left {
            padding-bottom: 5px;
            display: flex;
            flex-wrap: wrap;
            h5 {
                font-size: 15px;
                color: #364153;
                font-weight: 500;
                padding-bottom: 5px;
            }
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
            }
            .line-right {
                border-right: #ddd 1px solid;
                margin: 2px 10px;
            }
        }
        .right {
            display: flex;
            align-items: center;
            padding-bottom: 5px;
            .status {
                padding: 3px 15px;
                border-radius: 12px;
                h6 {
                    font-size: 12px;
                    color: #fff;
                    font-weight: 400;
                }
            }
            img {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
    .center-div {
        padding: 2px 0;
        align-items: center;
        .left-box {
            .store {
                display: flex;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                    padding: 4px;
                    background-color: #ddd;
                    border-radius: 50%;
                    cursor: pointer;
                }
                .name {
                    padding-left: 10px;
                    p {
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        color: #364153;
                    }
                    h2 {
                        color: #0091d6;
                        font-size: 17px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                        cursor: pointer;
                    }
                    .chat {
                        display: flex;
                        align-items: center;
                        .btn-chat {
                            cursor: pointer;
                            font-size: 12px;
                            margin-right: 7px;
                            justify-content: flex-end;
                            border: #43a047 2px solid;
                            padding: 2px 10px;
                            border-radius: 10px;
                            text-align: center;
                            color: #43a047;
                            -moz-transition: all .2s ease-in;
                            -o-transition: all .2s ease-in;
                            -webkit-transition: all .2s ease-in;
                            transition: all .2s ease-in;
                        }
                        .btn-chat:hover {
                            background-color: #1e88b9;
                            border: #1e88b9 2px solid;
                            p {
                                color: #fff;
                            }
                        }
                        p {
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            color: #43a047;
                        }
                    }
                }
            }
            .address {
                padding: 10px 0;
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #364153;
                    text-transform: capitalize;
                }
                h2 {
                    color: #364153;
                    font-size: 17px;
                    font-weight: 500;
                }
            }
           
            .vendor-list {
                padding: 7px 0;
                display: flex;
                flex-wrap: wrap;
                .box-vendor {
                    border: #ddd 1px solid;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 10px;
                    margin: 5px;
                    border-radius: 5px;
                    h3 {
                        text-transform: capitalize;
                        font-size: 15px;
                        color: #000;
                        font-weight: 400;
                    }
                }
                
            }
        }
        .right-box {
            display: flex;
            // justify-content: flex-end;.
            flex-wrap: wrap;
            .total-discount {
                // border-left: #ddd solid 1px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 10px;
                // padding: 5px 15px;
                p {
                    font-size: 14px;
                    color: #000;
                    font-weight: 300;
                    padding: 0 10px;
                }
                h3 {
                    font-size: 15px;
                    color: #000;
                    font-weight: bold;
                    margin: 0;
                }
            }
        }
    }
    .bottom-div {
        display: flex;
        justify-content: space-between;        
        align-items: center;
        .left-div {
            p {
                font-size: 14px;
                color: #000;
                font-weight: 500;
                text-decoration: underline;
            }
        }
        .right-div {
            display: flex;
            align-items: center;
            .btn-confirm {
                margin: 0 5px;
                button {
                    cursor: pointer;
                    background-color: #fff;
                    border: #43a047 solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 5px;
                    padding: 6px 14px;
                    transition: all 0.3s ease;
                    width: 100%;
                    text-align: center;
                    color: #43a047;
                    font-size: 15px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    border: #ddd solid 1px;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                    font-weight: 500;
                    border: none;
                }
            }
            .btn-confirm:hover {
                margin: 0 5px;
                button {
                    cursor: pointer;
                    background-color: #43a047;
                    border: #fff solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 5px;
                    padding: 6px 14px;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                    outline: none;
                    border: none;
                    font-weight: 500;
                }
            }
            
            .view-btn {
                display: flex;
                cursor: pointer;
                align-items: center;
                padding: 0 10px;
                img {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                }
                p {
                    font-size: 15px;
                    color: #43a047;
                    font-weight: bold;
                }
            }
        }
    }
}



.item-card-invoice {
    margin-top: 15px;
    max-height: calc(100vh - 250px);
    overflow-x: auto;
}

.selected-item {
    box-shadow: #2ecc71 0px 0px 5px !important;
    background-color: #c8e6c9 !important;
    .top-div {
        background-color: #c8e6c9 !important;
        box-shadow: #fff 0px 2px 0px !important;
    }
    .card-product-items {
        .item-product {
            background-color: #fff !important;
        }
    }
}


#submits-btn-preferred {
    // display: flex;
    // justify-content: flex-end;
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    // display: none;
}

.container-content-preferred {
    min-height: 20vh;
    .card-content {
        padding-top: 1rem;
    }
    .card-company {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: #ddd 0px 0px 8px;
        padding: 1rem;
        .top-header {
            display: flex;
            justify-content: space-between;
            h2 {
                font-size: 20px;
                color: #000;
                font-weight: 500;
                margin: 0;
                padding: 0;
            }
        }
        .code {
            width: fit-content;
            p {
                font-size: 14px;
                color: #0091d6;
                font-weight: 500;
                margin: 0;
            }
        }
    }
    .form-preferred {
        margin-top: 1rem;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: #ddd 0px 0px 8px;
        padding: 1rem;
        h2 {
            font-size: 15px;
            color: #000;
            font-weight: 400;
            margin: 0;
            padding: 0;
        }
        .form {
            h6 {
                font-size: 15px;
                color: #000;
                font-weight: 400;
                margin: 0;
                padding: 0;
            }
        }
        .form-product {
            padding-top: 1rem;
            h6 {
                font-size: 15px;
                color: #000;
                font-weight: 500;
                margin: 0;
                padding: 0;
            }
        }
    }
    
}