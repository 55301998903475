@use '../breakpoints/breakpoints.scss';


.header-search {
    background-color: #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    @include breakpoints.media('>phone', '<=tablet') {
        border-radius: 2px;
    }
    .category {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;;
        background-color: #fff;
        border-radius: 5px;
        padding: 15px 15px;
        color: #000;
        font-weight: 400;
        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
        p {
            padding-left: 5px;
            font-size: 14px;
        }
        @include breakpoints.media('>tablet', '<=desktop') {
            p {
                font-size: 10px;
            }
        }
    }
    .container-menu {
        z-index: 500;
        background-color: #fff;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 5px;
        padding: 15px 20px;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        .box-menu {
            display: flex;
            flex-direction: row;
            .list-menu {
                min-width: 192px;
                .title {
                    p {
                        color: #000;
                        font-size: 15px;
                        padding: 2px 0px;
                        margin-right: 10px;
                    }
                    cursor: pointer;
                    margin: 2px 0;
                    padding: 5px;
                    display: flex;
                    justify-content: space-between;
                    .icon-right {
                        color: #000;
                    }
                }
                .title:hover {
                    cursor: pointer;
                    margin: 2px 0;
                    padding: 5px;
                    display: flex;
                    justify-content: space-between;
                    // border: 1px solid #43a047;
                    background-color: #43a047;
                    border-radius: 8px;
                    p {
                        color: #fff;
                        font-size: 15px;
                        padding: 2px 5px;
                        margin-right: 10px;
                    }
                    .icon-right {
                        color: #fff;
                    }
                }
                .title-active {
                    cursor: pointer;
                    margin: 2px 0;
                    padding: 5px;
                    display: flex;
                    justify-content: space-between;
                    // border: 1px solid #43a047;
                    background-color: #43a047;
                    border-radius: 8px;
                    p {
                        color: #fff;
                        font-size: 15px;
                        padding: 2px 5px;
                        margin-right: 10px;
                    }
                    .icon-right {
                        color: #fff;
                    }
                }
            }
            .divider-middle {
                width: 1px;
                height: 100%;
                background-color: #ddd;
                margin: 0 10px;
            }
        }
        
    }
    .search-box {
        display: flex;
        align-items: center;;
        background-color: #fff;
        border-radius: 5px;
        padding: 0px 10px;
        input {
            background-color: #fff;
            padding: 16px 10px;
            width: 100%;
            border: none;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 300;
            color: #000;
            &:focus {
                outline: none;
            }
        }
        #select-style-addresss {
            width: 150px;
            border-style: none !important;
        }
        .notyping {
            display: flex;
            align-items: center;
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        .typing {
            display: flex;
            align-items: center;
            .removeimg {
                width: 17px;
                height: 17px;
                cursor: pointer;
            }
            .divider-line {
                width: 1px;
                height: 20px;
                background-color: #000;
                margin: 0 15px;
            }
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        
    }
    .hover-recently {
        position: relative;
        .container-hover-recently {
            position: absolute;
            width: 98%;
            background-color: #fff;
            box-shadow: #ddd 0px 0px 10px;
            border-radius: 6px;
            padding: 15px;
            .container-content {
                height: 500px;
                overflow-y: auto;
                overflow-x: hidden;
                .content {
                    border-radius: 6px;
                    margin: 15px;
                    .image-card-box-pr {
                        width: 100%;
                        background-color: #fff;
                        border-radius: 10px;
                        min-height: 220px;
                        box-shadow: #ddd 0px 0px 10px;
                        .zoom-effect-container {
                            position: relative;
                            width: 100%;
                            overflow: hidden;
                            .image-card-new {
                                height: 150px;
                                width: auto;
                                cursor: pointer;
        
                            }
                            
                            .next-arrow-slider {
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                z-index: 100;
                                cursor: pointer;
                                background: rgba(255, 255, 255, 0.651);
                                border-radius:50%;
                                padding-top: 2px;
                                padding-bottom: 1px;
                                padding-right: 2px;
                                padding-left: 4px;
                                color: #000;
                                cursor: pointer;
                                img {
                                    width: 15px;
                                    height: 10px;
                                }
                            }
                            .prev-arrow-slider {
                                position: absolute;
                                left: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                z-index: 100;
                                cursor: pointer;
                                background: rgba(255, 254, 254, 0.651);
                                border-radius:50%;
                                padding-top: 2px;
                                padding-bottom: 1px;
                                padding-right: 4px;
                                padding-left: 2px;
                                color: #ddd;
                                img {
                                    width: 15px;
                                    height: 10px;
                                }
                            }
                            .dot-mapping {
                                display: flex;
                                position: absolute;
                                top: 90%;
                                left: 50%;
                                transform: translateY(-90%);
                                transform: translateX(-50%);
                                z-index: 100;
                            }
                            .dot-slider {
                                height: 7px;
                                width: 7px;
                                background-color: rgb(184, 180, 180);
                                border-radius: 50%;
                                display: inline-block;
                                cursor: pointer;
                                margin-left: 2px;
                                margin-right: 2px;
                            }
                            .dot-slider-active {
                                height: 8px;
                                width: 8px;
                                background-color: rgb(48, 247, 21);
                                border-radius: 50%;
                                display: inline-block;
                                cursor: pointer;
                                margin-left: 2px;
                                margin-right: 2px;
                            }
                        }
                        .title-name {
                            margin-top: 0px;
                            margin-left: 15px;
                            margin-right: 15px;
                            min-height: 30px;
                            h1 {
                                text-transform: capitalize;
                                color: #000;
                                font-size: 14px;
                                font-weight: 400;
                                letter-spacing: 0.1px;
                            }
                        }
                        .title-brand {
                            margin-top: 7px;
                            margin-left: 5px;
                            margin-right: 15px;
                            margin-bottom: 0px;
                            width: fit-content;
                            border-radius: 5px;
                            // border: #3bb001 1px solid;
                            padding: 4px 10px;
                            h4 {
                                text-transform: capitalize;
                                width: fit-content;
                                color: #3bb001;
                                font-size: 11px;
                                font-weight: 300;
                            }
                        }
                        .title-price {
                            margin-top: 5px;
                            margin-left: 15px;
                            margin-right: 15px;
                            h1 {
                                text-transform: capitalize;
                                color: #e64542;
                                font-size: 15px;
                                font-weight: bold;
                                letter-spacing: 0.1px;
                            }
                        }
                        .title-discount {
                            display: flex;
                            align-items: center;
                            margin-top: 0px;
                            margin-left: 15px;
                            margin-right: 15px;
                            h2 {
                                text-transform: capitalize;
                                color: #afc0c9;
                                font-size: 11px;
                                font-weight: 300;
                                letter-spacing: 0.1px;
                                padding-right: 5px;
                                text-decoration: line-through;
                            }
                            .discount {
                                background-color: #e8505b;
                                border-radius: 5px;
                                padding: 2px 5px;
                                margin-right: 7px;
                                h3 {
                                    color: #fff;
                                    font-size: 12px;
                                    font-weight: 300;
                                }
                            }
                            .info-box {
                                padding-top: 3px;
                                // margin: 0 7px;
                                cursor: pointer;
                                img {
                                    width: 22px;
                                }
                                p {
                                    font-size: 10px;
                                }
                            }
                        }
                        .title-discount-amount {
                            display: flex;
                            align-items: center;
                            margin-top: 0px;
                            margin-left: 15px;
                            margin-right: 15px;
                            h2 {
                                text-transform: capitalize;
                                color: #afc0c9;
                                font-size: 11px;
                                font-weight: 300;
                                letter-spacing: 0.1px;
                                padding-right: 5px;
                                text-decoration: line-through;
                            }
                            .discount {
                                background-color: #ff9f43;
                                border-radius: 5px;
                                padding: 2px 5px;
                                margin-right: 7px;
                                h3 {
                                    color: #fff;
                                    font-size: 12px;
                                    font-weight: 300;
                                }
                            }
                            .info-box {
                                padding-top: 3px;
                                // margin: 0 7px;
                                cursor: pointer;
                                img {
                                    width: 22px;
                                }
                                p {
                                    font-size: 10px;
                                }
                            }
                        }
                        .title-discount-special {
                            display: flex;
                            align-items: center;
                            margin-top: 0px;
                            margin-left: 15px;
                            margin-right: 15px;
                            h2 {
                                text-transform: capitalize;
                                color: #afc0c9;
                                font-size: 11px;
                                font-weight: 300;
                                letter-spacing: 0.1px;
                                padding-right: 5px;
                                text-decoration: line-through;
                            }
                            .discount {
                                background-color: #26b99a;
                                border-radius: 5px;
                                padding: 2px 5px;
                                margin-right: 7px;
                                h3 {
                                    color: #fff;
                                    font-size: 12px;
                                    font-weight: 300;
                                }
                            }
                            .info-box {
                                padding-top: 3px;
                                // margin: 0 7px;
                                cursor: pointer;
                                img {
                                    width: 22px;
                                }
                                p {
                                    font-size: 10px;
                                }
                            }
                        }
                        .box-qty-btn {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            padding:10px;
                            .add-qty {
                                align-items: center;
                                padding: 8px 5px;
                                margin-top: 5px;
                                margin-bottom: 5px;
                                border-radius: 10px;
                                background-color: #fff;
                                box-shadow: #ddd 0px 0px 8px;
                                // border: #2d7230 solid 1px;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                img {
                                    width: 20px;
                                    height: 20px;
                                }
                                input {
                                    margin: 0 8px;
                                    width: 50px;
                                    text-align: center;
                                    border-radius: 8px;
                                    height: 20px;
                                    // border: none;
                                }
                            }
                            .add-qty-error {
                                box-shadow: red 0px 0px 5px;
                            } 
                            .add-btn-box {
                                margin-top: 5px;
                                text-align: center;
                                cursor: pointer;
                                background-color: #43a047;
                                border-radius: 10px;
                                box-shadow: #ddd 0px 0px 10px;
                                padding: 3px 10px;
                                p {
                                    color: #fff;
                                }
                                margin-bottom: 5px;
                            }
                            .add-qty-box {
                                margin: 0 2px;
                                cursor: pointer;
                            }
                            .error-qty {
                                text-align: center;
                                p {
                                    font-size: 12px;
                                    color: red;
                                }
                            }
                            
                        }
                        .button-cards { 
                            // margin: 10px;
                            display: flex;
                            flex-direction: column;
                            padding: 15px;
                            justify-content: space-between;
                            margin-bottom: 5px;
                            .pr {
                                cursor: pointer;
                                margin-bottom: 5px;
                                font-size: 14px;
                                justify-content: flex-start;
                                background-color: #43a047;
                                border-radius: 10px;
                                text-align: center;
                                padding: 8px;
                                color: #fff;
                                -moz-transition: all .2s ease-in;
                                -o-transition: all .2s ease-in;
                                -webkit-transition: all .2s ease-in;
                                transition: all .2s ease-in;
                            }
                            .pr:hover {
                                background-color: #2d7230;
                            }
                            .template {
                                cursor: pointer;
                                font-size: 15px;
                                justify-content: flex-end;
                                border: #43a047 2px solid;
                                padding: 8px 10px;
                                border-radius: 10px;
                                text-align: center;
                                color: #43a047;
                                -moz-transition: all .2s ease-in;
                                -o-transition: all .2s ease-in;
                                -webkit-transition: all .2s ease-in;
                                transition: all .2s ease-in;
                            }
                            .template:hover {
                                background-color: #f1eeee;
                                color: #398f3d;
                                border: #398f3d 2px solid;
                            }
                        }
                    }
                }
        
            }
        }
    }
    .recently-view {
        padding: 16px 10px;
        display: flex;
        border-radius: 5px;
        cursor: pointer;
        justify-content: center;
        background-color: #fff;
        img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
        
    }
}


.box-breadcrumbs-search {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #000;
}

.container-product {
    margin-top: 10px;
    .menu-left {
        background-color: #fff;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 6px;
        padding: 15px;
        // height: 500px;
        .area {
            .title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 8px;
                align-content: center;
                cursor: pointer;
                .icon-expand {
                    color: #43a047;
                }
                p {
                    font-weight: bold;
                    color: #43a047;
                }
            }
            // border-bottom: #ddd solid 1px;
            .checkbox-area {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                max-height: 200px;
                overflow-y: auto;
                overflow-x: hidden;
                label {
                    font-size: 14px;
                    cursor: pointer;
                    padding-left: 3px;
                    text-transform: capitalize;
                }
                input {
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
                padding-bottom: 5px;
                .input-brand {
                    margin-bottom: 5px;
                    input {
                        cursor: auto;
                        width: 100%;
                        border: none;
                        border-bottom: 1px solid #ddd;
                        padding: 5px 0;
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
            h6 {
                padding-top: 10px;
                padding-bottom: 5px;
                font-size: 15px;
                color: #43a047;
                cursor: pointer;
                font-weight: bold;
            }
        }
        .brand {
            margin-top: 20px;
            .title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 8px;
                align-content: center;
                cursor: pointer;
                .icon-expand {
                    color: #43a047;
                }
                p {
                    font-weight: bold;
                    color: #43a047;
                }
            }
            .checkbox-brand {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                max-height: 350px;
                overflow-y: auto;
                overflow-x: hidden;
                label {
                    font-size: 14px;
                    cursor: pointer;
                    padding-left: 3px;
                    text-transform: capitalize;
                }
                input {
                    margin-right: 5px;
                    margin-bottom: 5px;
                    cursor: pointer;
                }
                h6 {
                    padding-top: 10px;
                    padding-bottom: 5px;
                    font-size: 15px;
                    color: #43a047;
                    cursor: pointer;
                    font-weight: bold;
                }
                padding-bottom: 5px;
                .input-brand {
                    margin-bottom: 5px;
                    input {
                        cursor: auto;
                        width: 100%;
                        border: none;
                        border-bottom: 1px solid #ddd;
                        padding: 5px 0;
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }
        .vendor {
            margin-top: 20px;
            .title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 8px;
                align-content: center;
                cursor: pointer;
                .icon-expand {
                    color: #43a047;
                }
                p {
                    font-weight: bold;
                    color: #43a047;
                }
            }
            .checkbox-vendor {
                display: flex;
                flex-direction: column;
                margin-bottom: 5px;
                max-height: 350px;
                overflow-y: auto;
                overflow-x: hidden;
                input {
                    margin-right: 5px;
                    margin-bottom: 5px;
                    cursor: pointer;
                }
                label {
                    font-size: 14px;
                    cursor: pointer;
                    padding-left: 3px;
                    text-transform: capitalize;
                }
                h6 {
                    padding-top: 10px;
                    padding-bottom: 5px;
                    font-size: 15px;
                    color: #43a047;
                    cursor: pointer;
                    font-weight: bold;
                }
                padding-bottom: 5px;
                .input-brand {
                    margin-bottom: 5px;
                    input {
                        cursor: auto;
                        width: 100%;
                        border: none;
                        border-bottom: 1px solid #ddd;
                        padding: 5px 0;
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }
        .load-skeleton {
            margin-bottom: 15px;
            margin-top: 15px;
            .title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 8px;
                align-content: center;
                cursor: pointer;
                .icon-expand {
                    color: #43a047;
                }
                p {
                    font-weight: bold;
                    color: #757575;
                }
            }
            // border-bottom: #ddd solid 1px;
            .checkbox-load {
                display: flex;
                flex-direction: column;
                margin-bottom: 5px;
                input {
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
                label {
                    cursor: pointer;
                    padding-left: 3px;
                    text-transform: capitalize;
                }
                h6 {
                    padding-top: 10px;
                    padding-bottom: 5px;
                    font-size: 15px;
                    color: #43a047;
                    cursor: pointer;
                    font-weight: bold;
                }
            }
        }
    }
    .filter {
        background-color: #fff;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 6px;
        padding: 5px 15px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        p {
            font-size: 17px;
            padding-bottom: 2px;
        }
        span {
            // color: #43a047;
            text-transform: capitalize;
        }
        .sort {
            padding: 10px 0px;
            display: flex;
            align-items: center;
            h6 {
                font-size: 16px;
                color: #43a047;
                padding-right: 15px;
            }
            select {
                border: 1px solid #43a047;
                padding: 5px 10px;
                border-radius: 5px;
                outline: none;
                background-color: #fff;
                font-size: 16px;
                color: #43a047;
                cursor: pointer;
            }
        }
        .filter-product {
            display: flex;
            align-items: center;
            cursor: pointer;
            border: 1px solid #43a047;
            padding: 2px 10px;
            border-radius: 5px;
            p {
                padding-left: 5px;
                color: #43a047;
            }
        }
        @include  breakpoints.media('>1199px') {
            .filter-product {
                display: none;
            }
        }
    }
    .content {
        border-radius: 6px;
        margin-top: 10px;
        min-height: 70vh;
    }
}

#product-notfound {
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
    img {
        width: 100px;
        height: 100px;
    }
    p {
        font-size: 15px;
    }
    h6 {
        font-size: 16px;
        color: #43a047;
        font-weight: bold;
        padding-bottom: 5px;
    }
}

.image-card-box {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    min-height: 420px;
    box-shadow: #ddd 0px 0px 10px;
    .zoom-effect-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        .image-card-new {
            height: 170px;
            width: auto;
            cursor: pointer;

        }
        
        .next-arrow-slider {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 100;
            cursor: pointer;
            background: rgba(255, 255, 255, 0.651);
            border-radius:50%;
            padding-top: 5px;
            padding-bottom: 3px;
            padding-right: 2px;
            padding-left: 4px;
            color: #000;
            cursor: pointer;
            img {
                width: 22px;
                height: 16px;
            }
        }
        .prev-arrow-slider {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 100;
            cursor: pointer;
            background: rgba(255, 254, 254, 0.651);
            border-radius:50%;
            padding-top: 5px;
            padding-bottom: 3px;
            padding-right: 4px;
            padding-left: 2px;
            color: #ddd;
            img {
                width: 22px;
                height: 16px;
            }
        }
        .dot-mapping {
            display: flex;
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translateY(-90%);
            transform: translateX(-50%);
            z-index: 100;
        }
        .dot-slider {
            height: 7px;
            width: 7px;
            background-color: rgb(184, 180, 180);
            border-radius: 50%;
            display: inline-block;
            cursor: pointer;
            margin-left: 2px;
            margin-right: 2px;
        }
        .dot-slider-active {
            height: 8px;
            width: 8px;
            background-color: rgb(48, 247, 21);
            border-radius: 50%;
            display: inline-block;
            cursor: pointer;
            margin-left: 2px;
            margin-right: 2px;
        }
    }
    .title-name {
        margin-left: 15px;
        margin-right: 15px;
        min-height: 40px;
        h1 {
            text-transform: capitalize;
            color: #000;
            font-size: 17px;
            font-weight: 400;
            letter-spacing: 0.1px;
        }
    }
    .title-vendor {
        margin-top: 7px;
        margin-left: 5px;
        margin-right: 15px;
        margin-bottom: 0px;
        width: fit-content;
        border-radius: 5px;
        // border: #3bb001 1px solid;
        padding: 4px 10px;
        h4 {
            text-transform: capitalize;
            width: fit-content;
            color: #3bb001;
            font-size: 11px;
            font-weight: 300;
        }
    }
    .title-brand {
        margin-top: 7px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 5px;
        width: fit-content;
        border-radius: 5px;
        border: #3bb001 1px solid;
        padding: 4px 10px;
        h4 {
            width: fit-content;
            color: #3bb001;
            font-size: 12px;
            font-weight: 300;
        }
    }
    .title-price {
        margin-top: 10px;
        margin-left: 15px;
        margin-right: 15px;
        h1 {
            text-transform: capitalize;
            color: #e64542;
            font-size: 19px;
            font-weight: bold;
            letter-spacing: 0.1px;
        }
    }
    .title-discount {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-left: 15px;
        margin-right: 15px;
        h2 {
            text-transform: capitalize;
            color: #afc0c9;
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0.1px;
            padding-right: 5px;
            text-decoration: line-through;
        }
        .discount {
            background-color: #e8505b;
            border-radius: 5px;
            padding: 2px 5px;
            margin-right: 7px;
            h3 {
                color: #fff;
                font-size: 12px;
                font-weight: 300;
            }
        }
        .info-box {
            padding-top: 3px;
            // margin: 0 7px;
            cursor: pointer;
            img {
                width: 22px;
            }
            p {
                font-size: 10px;
            }
        }
    }
    .title-discount-amount {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-left: 15px;
        margin-right: 15px;
        h2 {
            text-transform: capitalize;
            color: #afc0c9;
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0.1px;
            padding-right: 5px;
            text-decoration: line-through;
        }
        .discount {
            background-color: #ff9f43;
            border-radius: 5px;
            padding: 2px 5px;
            margin-right: 7px;
            h3 {
                color: #fff;
                font-size: 12px;
                font-weight: 300;
            }
        }
        .info-box {
            padding-top: 3px;
            // margin: 0 7px;
            cursor: pointer;
            img {
                width: 22px;
            }
            p {
                font-size: 10px;
            }
        }
    }
    .title-discount-special {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-left: 15px;
        margin-right: 15px;
        h2 {
            text-transform: capitalize;
            color: #afc0c9;
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0.1px;
            padding-right: 5px;
            text-decoration: line-through;
        }
        .discount {
            background-color: #26b99a;
            border-radius: 5px;
            padding: 2px 5px;
            margin-right: 5px;
            h3 {
                color: #fff;
                font-size: 12px;
                font-weight: 300;
            }
        }
        .info-box {
            padding-top: 3px;
            // margin: 0 7px;
            cursor: pointer;
            img {
                width: 22px;
            }
            p {
                font-size: 10px;
            }
        }
    }
    .button-cards { 
        // margin: 10px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        justify-content: space-between;
        margin-bottom: 5px;
        .pr {
            cursor: pointer;
            margin-bottom: 5px;
            font-size: 13px;
            justify-content: flex-start;
            background-color: #43a047;
            border-radius: 10px;
            text-align: center;
            padding: 10px;
            color: #fff;
            -moz-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in;
        }
        .pr:hover {
            background-color: #2d7230;
        }
        .template {
            cursor: pointer;
            font-size: 13px;
            justify-content: flex-end;
            border: #43a047 2px solid;
            padding: 8px 10px;
            border-radius: 10px;
            text-align: center;
            color: #43a047;
            -moz-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in;
        }
        .template:hover {
            background-color: #f1eeee;
            color: #398f3d;
            border: #398f3d 2px solid;
        }
    }
}


.product-dialog-add {
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 7px;
    padding: 10px;
    margin: 5px 5px;
    .item-product {
        display: flex;
        font-size: 12px;
        img {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            background-color: #fff;
        }
        .right-product {
            width: 100%;
            margin: 0 15px;
            .title-product {
                h2 {
                    padding-bottom: 5px;
                    font-size: 20px;
                    font-weight: 500;
                    color: #364153;
                }
            }
            .tax {
                display: flex;
                align-items: center;
                padding: 5px 0;
                p {
                    font-size: 12px;
                    font-weight: bold;
                    color: #364153;
                }
                
            }
            .price {
                .discount-box {
                    display: flex;
                    padding: 5px 0;
                    .discount {
                        background-color: #e8505b;
                        border-radius: 5px;
                        padding: 2px 5px;
                        h3 {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                    .discount-amount {
                        background-color: #eaa24b;
                        border-radius: 5px;
                        padding: 2px 5px;
                        h3 {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                    .title-discount {
                        display: flex;
                        align-items: center;
                        margin-right: 5px;
                        h2 {
                            color: #364153;
                            font-size: 13px;
                            font-weight: 300;
                            padding-right: 5px;
                            text-decoration: line-through;
                        }
                    }
                }
                .title-price {
                    h1 {
                        color: #e84542;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
                
            }
            .quantity-btn {
                padding-top: 5px;
                display: flex;
                align-items: center;
                img {
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                }
                .input-qty {
                    input {
                        margin: 0 5px;
                        width: 50px;
                        text-align: center;
                        border-radius: 8px;
                        height: 20px;
                        border: #000 solid 2px;
                    }
                }

            }
            .quantity-btn-error {
                padding-top: 5px;
                display: flex;
                align-items: center;
                img {
                    cursor: pointer;
                    padding: 1px;
                    // background-color: #ddd;
                    width: 20px;
                    height: 20px;
                }
                .input-qty {
                    input {
                        margin: 0 5px;
                        width: 50px;
                        text-align: center;
                        border-radius: 8px;
                        height: 20px;
                        border: red solid 2px;
                    }
                    input*:focus {
                        outline: none;
                        border: red solid 2px;
                    }
                    *:focus {
                        outline: 0;
                    }
                }
            }
            .bottom-content {
                margin-bottom: 5px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                .error-qty {
                    // padding-right: 55px;
                    padding-top: 5px;
                    padding-left: 15px;
                    p {
                        color: red;
                        font-size: 13px;
                        font-weight: 400;
                    }
                }
                .line-divide {
                    margin: 5px 5px;
                    border-left: #ddd solid 1px;
                }
                .div-one {
                    display: flex;
                    align-items: center;
                    padding: 10px 0;
                    .total-prices {
                        // border: #43a047 1px solid;
                        border-radius: 5px;
                        height: fit-content;
                        h2 {
                            font-size: 18px;
                            font-weight: bold;
                            color: #1b4559;
                        }
                    }
                    .total-tax {
                        border-radius: 5px;
                        h2 {
                            font-size: 13px;
                            font-weight: 300;
                            color: #1b4559;
                        }
                    }
                }
                .div-second {
                    display: flex;
                    flex-direction: column;
                    .text-info {
                        padding: 2px 0;
                        border-radius: 5px;
                        h2 {
                            font-size: 14px;
                            font-weight: 300;
                            color: #1b4559;
                        }
                    }
                }
               
            }
        }
    }
}

.box-Skeleton {
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 7px;
    padding: 10px;
    margin: 5px 5px;
}
.open-list-purchase {
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 7px;
    padding: 10px;
    margin: 5px 5px;
    h2 {
        padding-bottom: 5px;
        padding-left: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #364153;
    }
    .list {
        margin: 2px 0;
        max-height: 250px;
        overflow-y: auto;
        .item {
            border: #1b4559 solid 1px;
            border-radius: 5px;
            padding: 5px;
            margin: 5px 5px;
            text-align: center;
            cursor: pointer;
            h5 {
                font-size: 14px;
                font-weight: 300;
                color: #1b4559;
            }
        }
        .empty {
            border: #ddd solid 1px;
            border-radius: 5px;
            padding: 20px 5px;
            text-align: center;
            h5 {
                font-size: 14px;
                font-weight: 300;
                color: rgb(146, 146, 146);
            }
        }
        
    }
}

.hover-info {
    min-width: 200px;
    padding: 5px 10px;
    h6 {
        color: #000;
        font-size: 14px;
        font-weight: 300;
    }
}

.center-or {
    margin: 10px;
    h1 {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        font-weight: 300;
        color: #1b4559;
    }
    h1:before, h1:after{
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #ddd;
        margin: auto;
    }
    h1:before {
        margin-right: 10px
    }
    h1:after {
        margin-left: 10px
    }
}

.create-new {
    border-radius: 7px;
    margin: 10px 5px;
    .button-cards { 
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 5px;
        .pr {
            cursor: pointer;
            margin-bottom: 5px;
            font-size: 15px;
            justify-content: flex-start;
            background-color: #43a047;
            border-radius: 10px;
            text-align: center;
            padding: 10px;
            color: #fff;
            -moz-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in;
        }
        .pr:hover {
            background-color: #2d7230;
        }
    }
}

.tabs-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 5px;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    .item-tab {
        cursor: pointer;
        padding: 5px 15px;
        background-color: #ddd;
        border-radius: 10px;
        font-size: 15px;
        font-weight: 300;
        -moz-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        -webkit-transition: all .2s ease-in;
        transition: all .2s ease-in;
        h6 {
            font-size: 15px;
            font-weight: 300;
            color: #000;
        }
    }
    .item-tab:hover {
        background-color: #43a047;
        h6 {
            color: #fff;
        }
    }
    .active {
        background-color: #43a047;
        h6 {
            color: #fff;
        }
    }
}

.container-info {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    .content {
        padding: 10px;
        min-height: 30vh;
    }
    margin-bottom: 2rem;
    .content-contact-address {
        padding: 10px;
        .contact {
            h3 {
                font-size: 16px;
                font-weight: 500;
                color: #000;
            }
            .content-contact {
                padding-left: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                h4 {
                    font-size: 14px;
                    font-weight: 300;
                    color: #000;
                }
                .phone {
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 5px;
                    p {
                        font-size: 14px;
                        font-weight: 300;
                        color: #000;
                        padding-left: 0.5rem;
                    }
                }
                .address {
                    p {
                        font-size: 14px;
                        font-weight: 300;
                        color: #000;
                        padding-left: 0.5rem;
                    }
                }
            }
        }
    }
}

.schedule-info {
    padding: 10px;
    border-radius: 10px;
    .content {
        padding: 10px;
        min-height: 30vh;
    }
    margin-bottom: 2rem;
    .content-delivery-times {
        .schedule {
            h3 {
                font-size: 16px;
                font-weight: 500;
                color: #000;
            }
            background-color: #fff;
            border-radius: 10px;
            padding: 10px;
            .content-schedule {
                padding-left: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                h4 {
                    font-size: 14px;
                    font-weight: 300;
                    color: #000;
                }
                .cities {
                    h4 {
                        text-transform: capitalize;
                    }
                }
                .phone {
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 5px;
                    p {
                        font-size: 14px;
                        font-weight: 300;
                        color: #000;
                        padding-left: 0.5rem;
                    }
                }
                .address {
                    p {
                        font-size: 14px;
                        font-weight: 300;
                        color: #000;
                        padding-left: 0.5rem;
                    }
                }
            }
        }
        
    }
}

