@use '../breakpoints/breakpoints.scss';

.chat-floating-button {
    // height: 100vh;
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 150px;
    margin-bottom: 25px;
    background-color: #fff;
    box-shadow: rgb(184, 184, 184) 0 0 12px;
    border: rgb(202, 202, 202) solid 1px;
    border-radius: 20px;
    cursor: pointer;
    .floating-card-chat {
        display: flex;
        align-items: center;
        padding: 10px 1.5rem;
        img {
            width: 25px;
            height: 25px;
            margin-right: 7px;
        }
        h6 {
            font-size: 1.1rem;
            font-weight: 500;
            color: #43a047;
            margin-bottom: 0;
            text-transform: capitalize;
        }
        
    }
}

@include breakpoints.media('<=phone') {
    .chat-floating-button {
        display: none;
    }
    .hide-info-mobile {
        display: none !important;
    }
}
@include breakpoints.media('>phone', '<=895px') {
    .chat-floating-button {
        display: none;
    }
    .hide-info-mobile {
        display: none !important;
    }
}

.hide-info-mobile {
    display: flex;
    justify-content: flex-end;
}

.container-float-chat{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1500;
    margin-right: 40px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: #ddd 0 0 13px;
    border-radius: 15px;
    max-width: 500px;
    min-height: 400px;
    padding-top: 1rem;
    // padding: 1rem;
    h2 {
        font-size: 1.2rem;
        font-weight: 500;
        color: #43a047;
        margin-bottom: 0;
        text-transform: capitalize;
    }
    .dropwdown {
        cursor: pointer;
        img {
            width: 25px;
            height: 25px;
        }

    }
}

.chat-container {
    height: calc(100vh - 200px);
    position: relative;
    width: 100%;
    margin: auto;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
}

.chat-container-float {
    height: calc(100vh - 60vh);
    position: relative;
    width: 100%;
    margin: auto;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
}

@keyframes transitionUp {
    from {
        position:fixed;
        right: 40px;
        min-height:0px; 
        bottom: 0;
        opacity: -10;
        z-index:0; 
    }

    to{
        visibility: visible;
        position:fixed;
        height: 400px;
        width: 500px; 
        bottom: 10px;
        z-index:2000; 
        right:40px;
    }
}


@keyframes hiddenAnimation {
    from{
        position:fixed;
        height: 100px;
        min-height: 400px;
        width: 550px;
        right: 40px;
        bottom: 10px;
        z-index:2000; 
        right:40px;
    }

    to{
        visibility: visible;
        position:fixed;
        height: 100px;
        width: 550px;
        right: 40px;
        min-height:0px; 
        bottom: 0;
        opacity: -10;
        z-index:0; 
    }
    
}

.no-clicked{
    visibility: hidden;
}

.float-halfWidth{
    position:fixed;
    bottom:10px;
    right:40px;
    z-index:2000; 
    min-height:400px; 
    height: 400px;
    width: 180px;
    min-width:100px;
    display:flex; 
    flex-direction:row;
    animation-name: halfWidth;
    animation-duration: 0.6s;
}

@keyframes halfWidth {
    from{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width : 500px
    }
    to{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width : 180px
    }
}

.half-to-fullWidth{
    position:fixed;
    bottom:10px;
    right:40px;
    z-index:2000; 
    min-height:400px; 
    height: 400px;
    width: 500px;
    min-width:550px;
    display:flex; 
    flex-direction:row;
    animation-name: toFullWidth;
    animation-duration: 0.8s;
}

@keyframes toFullWidth {
    from{
        position:fixed;
        bottom:10px;
        right:40px;
        height: 400px;
        min-height:400px;
        width: 180px; 
        min-width: 180px;
        height: 0;
        width: 180px;
        z-index:2000; 
    }

    to{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width: 500px;
        min-width:550px;
    }
}

.float-close-halfwidth{
    visibility: hidden;
    animation-name: closeHalfWidth;
    animation-duration: 0.8s;
}

@keyframes closeHalfWidth {
    from{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width: 180px;
        min-width:100px;
        display:flex; 
    }

    to{
        visibility: visible;
        position:fixed;
        bottom:10px;
        right:40px;
        min-height:0;
        width: 180px; 
        min-width: 180px;
        height: 0;
        width: 180px;
        opacity: -10;
        z-index:0; 
    }
}

.float-fullWidth{
    position:fixed;
    bottom:10px;
    right:40px;
    z-index:2000; 
    min-height:400px; 
    height: 400px;
    width: 500px;
    min-width:550px;
    display:flex; 
    flex-direction:row;
    animation-name: fullWidth;
    animation-duration: 0.6s;
}

@keyframes fullWidth {
    from{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width: 100px;
    }
    to{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width: 500px;
    }
}

.buttonChatTransition{
    animation-name: buttonChat;
    animation-duration: 1s;
}

@keyframes buttonChat {
    from{
        opacity: 1;
    }

    to{
        opacity: -100;
    }
}


