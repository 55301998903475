@use '../breakpoints/breakpoints.scss';


.ReactModal__Overlay  {
    z-index: 9999 !important;
}

#rekomendasi {
    margin: 20px 0;
    h5 {
        color: #364153;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.5px;
        padding-bottom: 15px;
    }
    .image-card-box {
        width: 100%;
        // background-color: #d7d7d7;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 15px; 
        min-height: 250;
        .zoom-effect-container {
            position: relative;
            width: 100%;
            overflow: hidden;
            .image-card-new {
                height: 250;
                width: auto;
                cursor: pointer;
            }
            
            .next-arrow-slider {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 100;
                cursor: pointer;
                background: rgba(255, 255, 255, 0.651);
                border-radius:50%;
                padding-top: 5px;
                padding-bottom: 3px;
                padding-right: 2px;
                padding-left: 4px;
                color: #000;
                cursor: pointer;
                img {
                    width: 22px;
                    height: 16px;
                }
            }
            .prev-arrow-slider {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 100;
                cursor: pointer;
                background: rgba(255, 254, 254, 0.651);
                border-radius:50%;
                padding-top: 5px;
                padding-bottom: 3px;
                padding-right: 4px;
                padding-left: 2px;
                color: #ddd;
                img {
                    width: 22px;
                    height: 16px;
                }
            }
            .dot-mapping {
                display: flex;
                position: absolute;
                top: 90%;
                left: 50%;
                transform: translateY(-90%);
                transform: translateX(-50%);
                z-index: 100;
            }
            .dot-slider {
                height: 7px;
                width: 7px;
                background-color: rgb(184, 180, 180);
                border-radius: 50%;
                display: inline-block;
                cursor: pointer;
                margin-left: 2px;
                margin-right: 2px;
            }
            .dot-slider-active {
                height: 8px;
                width: 8px;
                background-color: rgb(48, 247, 21);
                border-radius: 50%;
                display: inline-block;
                cursor: pointer;
                margin-left: 2px;
                margin-right: 2px;
            }
        }
    }
}

.container-detail-product {
    margin: 1rem 0;
    .image-card-box {
        width: 100%;
        // background-color: #d7d7d7;
        border-radius: 15px;
        min-height: 460px;
        .zoom-effect-container {
            position: relative;
            width: 100%;
            overflow: hidden;
            .image-card-new {
                height: 460px;
                width: auto;
                cursor: pointer;
            }
            
            .next-arrow-slider {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 100;
                cursor: pointer;
                background: rgba(255, 255, 255, 0.651);
                border-radius:50%;
                padding-top: 5px;
                padding-bottom: 3px;
                padding-right: 2px;
                padding-left: 4px;
                color: #000;
                cursor: pointer;
                img {
                    width: 22px;
                    height: 16px;
                }
            }
            .prev-arrow-slider {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 100;
                cursor: pointer;
                background: rgba(255, 254, 254, 0.651);
                border-radius:50%;
                padding-top: 5px;
                padding-bottom: 3px;
                padding-right: 4px;
                padding-left: 2px;
                color: #ddd;
                img {
                    width: 22px;
                    height: 16px;
                }
            }
            .dot-mapping {
                display: flex;
                position: absolute;
                top: 90%;
                left: 50%;
                transform: translateY(-90%);
                transform: translateX(-50%);
                z-index: 100;
            }
            .dot-slider {
                height: 7px;
                width: 7px;
                background-color: rgb(184, 180, 180);
                border-radius: 50%;
                display: inline-block;
                cursor: pointer;
                margin-left: 2px;
                margin-right: 2px;
            }
            .dot-slider-active {
                height: 8px;
                width: 8px;
                background-color: rgb(48, 247, 21);
                border-radius: 50%;
                display: inline-block;
                cursor: pointer;
                margin-left: 2px;
                margin-right: 2px;
            }
            .thumbnail-container {
                display: flex;
                justify-content: center;
                margin: 10px;
                overflow-y: auto;
                .thumb-img {
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                    cursor: pointer;
                    object-fit: cover;
                    border-radius: 5px;
                }
                .active {
                    border: 2px solid rgb(48, 247, 21);
                    width: 48px;
                    height: 48px;
                }
            }
        }
    }
    
    .right-detail {
        margin-left: 15px;
        margin-right: 15px;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 15px;
        padding: 20px;
        .verified {
            display: flex;
            align-items: center;
            img {
                width: 20px;
            }
            h6 {
                padding-left: 5px;
                width: fit-content;
                color: #32ba7c;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.5px;
            }
        }
        
        .title-name {
            margin-top: 10px;
            // min-height: 40px;
            h1 {
                text-transform: capitalize;
                color: #364153;
                font-size: 25px;
                font-weight: bold;
                letter-spacing: 0.5px;
            }
        }
        .title-info {
            margin-top: 8px;
            display: flex;
            .line {
                border-right: #000 1px solid;
                margin: 0 15px;
            }
            p {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.5px;
            }
        }
        .title-brand {
            margin-top: 15px;
            width: fit-content;
            border-radius: 20px;
            border: #3bb001 1px solid;
            padding: 4px 15px;
            h4 {
                width: fit-content;
                color: #3bb001;
                font-size: 12px;
                font-weight: 300;
            }
        }
        .price-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px 0;
            .title-price {
                padding-right: 10px;
                h1 {
                    text-transform: capitalize;
                    color: #e64542;
                    font-size: 30px;
                    font-weight: 700;
                }
            }
            .title-discount {
                padding-right: 10px;
                h2 {
                    text-transform: capitalize;
                    color: #364153;
                    font-size: 17px;
                    font-weight: 500;
                    letter-spacing: 0.1px;
                    text-decoration: line-through;
                }
            }
            .discount {
                background-color: #e8505b;
                border-radius: 15px;
                padding: 5px 10px;
                h3 {
                    color: #fff;
                    font-size: 12px;
                    font-weight: 300;
                }
            }
        }
        .extra-information {
            margin-top: 15px;
            margin-bottom: 10px;
            .info {
                display: flex;
                padding: 2px 0;
                h4 {
                    color: #364153;
                    font-size: 15px;
                    font-weight: 500;
                }
                h3 {
                    min-width: 25%;
                    color: #364153;
                    font-size: 15px;
                    font-weight: bold; 
                }
            }
        }
        .button-cards { 
            margin: 5px 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: 0.5rem;
            p {
                font-family: sans-serif;
            }
            .pr {
                cursor: pointer;
                font-size: 15px;
                justify-content: flex-start;
                background-color: #43a047;
                border-radius: 10px;
                text-align: center;
                margin-right: 8px;
                padding: 9px;
                color: #fff;
                -moz-transition: all .2s ease-in;
                -o-transition: all .2s ease-in;
                -webkit-transition: all .2s ease-in;
                transition: all .2s ease-in;
            }
            .pr:hover {
                background-color: #2d7230;
            }
            .template {
                cursor: pointer;
                font-size: 15px;
                margin-right: 8px;
                justify-content: flex-end;
                border: #43a047 2px solid;
                padding: 8px 10px;
                border-radius: 10px;
                text-align: center;
                color: #43a047;
                -moz-transition: all .2s ease-in;
                -o-transition: all .2s ease-in;
                -webkit-transition: all .2s ease-in;
                transition: all .2s ease-in;
            }
            .template:hover {
                background-color: #f1eeee;
                color: #398f3d;
                border: #398f3d 2px solid;
            }
        }
    }

    .seller {
        margin: 15px;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 15px;
        padding: 20px;
        .store {
            display: flex;
            align-items: center;
            img {
                width: 40px;
                height: 40px;
                padding: 4px;
                background-color: #ddd;
                border-radius: 50%;
            }
            cursor: pointer;
            .name {
                padding-left: 10px;
                p {
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    color: #364153;
                }
                h2 {
                    color: #364153;
                    font-size: 17px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                }
            }
        }
        .chat {
            margin-top: 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 0.5rem;
            .btn-chat {
                cursor: pointer;
                font-size: 12px;
                margin-right: 7px;
                justify-content: flex-end;
                border: #43a047 2px solid;
                padding: 7px 10px;
                border-radius: 10px;
                text-align: center;
                color: #43a047;
                -moz-transition: all .2s ease-in;
                -o-transition: all .2s ease-in;
                -webkit-transition: all .2s ease-in;
                transition: all .2s ease-in;
            }
            .btn-chat:hover {
                background-color: #f1eeee;
                color: #398f3d;
                border: #398f3d 2px solid;
            }
            p {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.5px;
                color: #364153;
            }
        }
    }

    .descriptions {
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 15px;
        padding: 20px;
        .menu-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .title-desc {
                border: #2d7230 1px solid;
                cursor: pointer;
                width: fit-content;
                border-radius: 10px;
                margin-right: 10px;
                h2 {
                    text-transform: capitalize;
                    color: #2d7230;
                    font-size: 15px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    padding: 7px 15px;
                }
            }
            .title-desc-active {
                background-color: #2d7230;
                cursor: pointer;
                width: fit-content;
                border-radius: 10px;
                margin-right: 10px;
                h2 {
                    text-transform: capitalize;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    padding: 7px 15px;
                }
            }
        }
        .product-detail {
            padding: 5px;
            .extra-information {
                margin-top: 15px;
                margin-bottom: 10px;
                .info {
                    display: flex;
                    padding: 2px 0;
                    h4 {
                        color: #364153;
                        font-size: 15px;
                        font-weight: 500;
                    }
                    h3 {
                        min-width: 20%;
                        color: #364153;
                        font-size: 15px;
                        font-weight: bold; 
                    }
                }
            }
        }
    }

    

    @include breakpoints.media('>phone', '<=tablet') {
        .image-card-box {
            width: 100%;
            background-color: #fff;
            border-radius: 10px;
            min-height: 300px;
            .zoom-effect-container {
                position: relative;
                width: 100%;
                overflow: hidden;
                .image-card-new {
                    height: 300px;
                    width: auto;
                    cursor: pointer;
                }
            }
        }
        .right-detail {
            margin: 0;
            .title-name {
                h1 {
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0.2px;;
                }
            }
            .extra-information {
                .info {
                    h3 {
                        min-width: 45%;
                    }
                }
            }
        }
        .seller {
            margin: 15px 0;
        }
    }
}