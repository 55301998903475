@use '../breakpoints/breakpoints.scss';


@include breakpoints.media('<=phone') {
    .container-component {
        flex-grow: 1;
        padding: 5px;
        width: 100%;
    }
    .filter-container {
        position: absolute;
        z-index: 20000;
        right: 3%;
        width: 350px;
    }
}
@include breakpoints.media('>phone', '<=tablet') {
    .container-component {
        flex-grow: 1;
        padding: 5px;
        width: 100%;
    }
    .filter-container {
        position: absolute;
        z-index: 20000;
        right: 3%;
        width: 350px;
    }
}
@include breakpoints.media('>tablet', '<=desktop') {
    .container-component {
        flex-grow: 1;
        padding: 10px 15px;
        width: 100%;
    }
    .filter-container {
        position: absolute;
        z-index: 20000;
        right: 3%;
        width: 400px;
    }
}


@include breakpoints.media('>desktop') {
    .container-component {
        flex-grow: 1;
        padding: 10px 20px;
        width: 100%;
    }
    .filter-container {
        position: absolute;
        z-index: 20000;
        right: 3%;
        width: 500px;
    }
}