@use '../variables/colors';


.paper-box {
    width: 200px;
    height: 100px;
    background-color: red;
    // .box-div {
    //     margin-right: 10px;
    // }
}

.rdt_TableHeadRow {
	border-radius: 5px;
    box-shadow: #ddd 0px 0px 5px;
}

.rdt_TableCol {
    font-weight: bold;
    color: azure;
    background-color: #43a047;
    font-size: 15px;
	text-transform: capitalize;
	color: #fff;
    height: 50px;
}

.rdt_TableCell {
    height: 45px;
    font-size: 12px;
}

.jsAMOb {
	height: 70px !important;
}


.img-notfound {
    margin-top: 2rem;
    img {
        width: 500px;
    }
	text-align: center;
	h5 {
		font-weight: 400;
		color: #000;
		font-size: medium;
	}
}

.text-notfound {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        margin-bottom: 20px;
    }
}

.ais-RefinementList-showMore {
    background-color: #43a047;
}

.ais-RefinementList-showMore :hover {
    background-color: #ddd;
}
.ais-ClearRefinements-button {
    background-color: #43a047;
}

.img-error-status {
    margin-top: 2rem;
    img {
        width: 300px
    }
	text-align: center;
	h5 {
		font-size: large;
		font-weight: 300;
		color: #000;
		font-size: 17px;
		padding-top: 10px;
	}
	h6 {
		font-weight: 300;
		color: #000;
		font-size: 15px;
		padding-top: 10px;
	}
	h1 {
		color: red;
		font-size: 22px;
		padding-top: 10px;
	}
}

.text-notfound-status {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        margin-bottom: 20px;
    }
}

.card-statistic {
	h4 {
		font-weight: 300;
		color: #000;
		font-family: 'Roboto', sans-serif;
		letter-spacing: 1px;
	}
    h3 {
        font-weight: 400;
		font-size: 25px;
		color: #43a047;
		padding-top: 5px;
    }
	img {
		width: 50px;
	}
	display: flex;
	justify-content: space-between;
}

.card-statistic-status {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    h3 {
        font-weight: 400;
    }
    h1 {
        font-size: 40px;
    }
}

.floating-button {
    // position: absolute;
    font-size: 14px;
    bottom: 0;
    left: 0;
    margin-top: 30px;
    margin-bottom: 10px;
}

.floating-drawer {
    position: absolute;
    bottom: 0;
    // margin-bottom: 25px;
    // margin-right: 40px;
}
.menu-pop-over {
    margin-top: 5px;
    .box-menu {
        min-width: 260px;
        list-style: none;
        padding: 20px;
        margin: 0;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                background-color: #1b4559;
                border-radius: 5px;
                margin-bottom: 7px;
                margin-top: 7px;
                cursor: pointer;
                font-size: 16px;
                color: #fff;
                padding: 5px 15px;
            }
        }
    }
}

.menu-cart-button {
    margin-top: 10px;
    button {
        border: none;
        background-color: transparent;
    }
}

.css-mlqt2n-MuiTableRow-root {
    height: 30px !important;
}
.box-menu-cart {
    margin-left: 40px;
    margin-right: 40px; 

    .content-cart {
        height: 58vh;
        overflow-y: scroll;
    }
    .content-cart-empty {
        height: 35vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img {
            width: 200px;
            height: 200px;
        }
    }
}

.total-cart {
    padding: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
        color: #1b4559;
        font-weight: 700;
    }
    
    .right-button {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        h6 {
            text-align: centers;
            cursor: pointer;
            font-weight: bold;
            font-size: 17px;
            color: #1b4559;
            margin-right: 20px;
        }
    }
}


.btn-icon-upload {
	border: 2px dotted #ccc;
	padding: 10px; 
	cursor: pointer;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}



.box-images-btn {
	cursor: pointer;
	border: #ccc solid 2px;
	width: 200px;
	height: 117px;
	border-radius: 5px;
	border-style: dotted;
	display: flex;
	justify-content: center;
	flex-direction: column;
    text-align: center;
	align-items: center;
	img {
		width: 45px;
	}
	p {
		font-size: 10px;
		cursor: pointer;
	}
}

.image-preview {
	position: relative;
	cursor: pointer;
	background-color: #f5f5f5;
	border-radius: 5px;
	width: 131px;
	img {
		width: 125px;
		height: 125px;
		padding: 5px;
	}
	.btn-remove-img {
		position: absolute;
		right: 0;
		display: none;
		color: #ee4054;
	}
}


.image-preview:hover {
	position: relative;
	.btn-remove-img{
		position: absolute;
		bottom: 0;
		color: #f91204;
		display: block;
	}

}

.link-file-name {
	color: #000;
	padding-left: 3px;
}
// card info 
.cart-menu {
    background-color: colors.$primaryColor;
    cursor: pointer;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
        color: #fff;
    }
    .cart-text {
        display: flex;
        align-items: center;
    }
}

.vendor-title {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    h4{
        padding-top: 5px;
        margin: 0;
    }
}

.sub-total-cart {
    display: flex;
    justify-content: space-between;
    h4 {
        font-weight: 600;
    }
}

.loading-button {
    display: flex;
    align-items: center;
    p {
        margin-right: 5px;
    }
}

.search-bar {
    background-color: #fff;
}

.sc-iwyWTf {
    padding-top: 25px;
    padding-bottom: 25px;
}   



.list-pr {
    width: 100%;
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    li{
        padding: 8px 0px;
        border: 2px solid colors.$primaryColor;
        color: #000;
        text-align: center;
        margin-left: 0px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 0px;
        border-radius: 4px;
        cursor: pointer;
    }
  }
  

  .list-item-tooltip {
      ul {
          list-style: none;
          padding-right: 5px;
          padding-left: 5px;
          color: #fff;
          .item-list-all {
              display: flex;
              flex-direction: row;
              span {
                  padding-right: 5px;
              }
          }
      }

  }


  .box-payment-choose {
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 10px;
      margin-top: 10px;
      text-align: center;
      a {
          color: #fff;
      }
  }

  .box-payment-method {
    color: #000;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    a {
        color: #000;
    }
}

.table-size-text {
    font-size: 10px;
}


.box-address {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 0.5rem;
	img {
		width: 100px;
		height: 100px;
		border-radius: 10px;
		margin-right: 10px;
	}
	.address-label {
		font-size: 18px;
		padding-bottom: 5px;
		font-weight: bold;
	}
	.address-name {
		font-size: 15px;
		font-weight: 400;
        padding-bottom: 2px;
        padding-top: 2px;
        text-transform: capitalize;
	}
}

.modules-items {
	border-radius: 10px;
	box-shadow: #ddd 0 0 10px;
	max-height: calc(100vh - 500px);
	overflow-y: auto;
}


// react-phone-input-code

/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: inherit;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
    height: 50px;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
    height: 40px;
    border: 1px solid #ccc;
    font-size: 15px;
    padding-left: 10px;
    margin-left: 5px;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
    margin-right: 7px;
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: 12px;
	height: 12px;
    color: #000;
	margin-left: 2px;
	border-style: solid;
	border-color: #000;
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}


.invitation-container {
	margin: 1rem 0;
	background-color: #fff;
	border: #fff solid 1px;
	box-shadow: #ddd 0px 0px 10px;
	padding: 2rem 1.5rem;
	border-radius: 12px;
	h4 {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0.5rem;
	}
	.action {
		display: flex;
		justify-content: flex-end;
		margin-top: 2rem;
	}

}
.button-create {
	button {
		cursor: pointer;
		background-color: #43a047;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 9px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		transition: all 0.3s ease;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}
.button-create:hover {
	button {
		cursor: pointer;
		background-color: #227c26;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 9px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}


.button-create-outlined {
	button {
		cursor: pointer;
		background-color: #fff;
		border: #227c26 solid 1px;
		// box-shadow: #227c26 0px 0px 10px;
		border-radius: 9px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		transition: all 0.3s ease;
		color: #227c26;
		font-size: 14px;
		font-weight: 500;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}
.button-create-outlined:hover {
	button {
		cursor: pointer;
		background-color: #0c5c10;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 9px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}

.overhiddenflow {
    height: calc(100vh - 310px);
    overflow: auto;
}

.overhiddenflowProduct {
	height: calc(100vh - 250px);
	overflow: auto;
}

.overhiddenflowProductPR {
	height: calc(100vh - 400px);
	overflow-x: auto;
}

.date-select {
    background-color: #fff;
    box-shadow: #ccc 0px 0px 12px;
    padding: 15px;
    border-radius: 7px;
    margin-top: 5px;
    .divider-btn {
        border-top: #ccc solid 1px;
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
}


#flex-date {
	display: flex;
	align-items: center;
	p {
		font-size: 14px;
		font-weight: 500;
		margin: 0;
	}
}



.btn_error {
	button {
		background-color: #e8505b !important;
	}
}

.btn_error:hover {
	button {
		background-color: #be0915 !important;
	}
}

.btn_outlined {
	button {
		background-color: #fff !important;
		border: #43a047 solid 1px !important;
		color: #43a047 !important;
	}
	:disabled {
		background-color: #ddd !important;
		border: #ddd solid 1px !important;
		color: rgb(133, 133, 133) !important;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}
.button-action {
	button {
		cursor: pointer;
		background-color: #43a047;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 11px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		transition: all 0.3s ease;
		color: #fff;
		font-size: 13px;
		font-weight: 400;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}
.button-action:hover {
	button {
		cursor: pointer;
		background-color: #227c26;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 11px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 13px;
		font-weight: 400;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}


.notfound-data {
	min-height: 265px;
	// width: 95%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
	padding: 1rem 10px;
	box-shadow: #ddd 0 0 10px;
	margin: 1px;
	text-align: center;
	h1 {
		font-size: 1.2rem;
		font-weight: 500;
		margin: 0;
		padding-bottom: 0.5rem;
	}
	p {
		font-size: 0.8rem;
		margin: 0;
		font-weight: 400;
	}
	img {
		margin-bottom: 1rem;
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
}


.card-six-tiles {
	background-color: #f4f4f4;
    // border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 1px;
    padding: 10px ;
}

.card-three-tiles {
	background-color: #f4f4f4;
    // border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 5px;
}

.card-lines-paper {
	background-color: #f4f4f4;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 1px;
    padding: 10px ;
	h2 {
		font-size: 1.2rem;
		font-weight: 500;
		margin: 0;
		text-align: center;
		padding-top: 5px;
		color: #43A047;
	}
}

.card-seller-sales {
	background-color: #f4f4f4;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 1px;
    padding: 10px ;
	h2 {
		font-size: 1.2rem;
		font-weight: 500;
		margin: 0;
		text-align: center;
		padding-top: 5px;
		color: #43A047;
	}
}

.table-card {
	background-color: #fff;
    box-shadow: #fff 0px 0px 10px;
    border-radius: 8px;
    padding: 5px ;
	margin: 0 10px;
}

.card-brand-sales {
	background-color: #f4f4f4;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 1px;
    padding: 10px ;
	h2 {
		font-size: 1.2rem;
		font-weight: 500;
		margin: 0;
		text-align: center;
		padding-top: 5px;
		color: #43A047;
	}
}



.tiles-paper {
	background-color: #fff;
    border: #f4f4f4 solid 1px;
    box-shadow: #f4f4f4 0px 0px 5px;
    border-radius: 10px;
    padding: 19px;
    
    .tiles-info {
        h4 {
            font-weight: 400;
            color: #000;
            font-size: 14px;
			padding-bottom: 5px;
        }
        h3 {
            font-weight: 500;
            font-size: 18px;
            color: #43A047;
        }
		h1 {
			font-weight: bold;
            font-size: 25px;
            color: #43A047;
		}
        display: flex;
        justify-content: space-between;
    }
}


.purchase-paper {
    background-color: #fff;
    border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 4px;
	margin: 5px;
    
    .purchase-info {
        h4 {
            font-weight: 400;
            color: #000;
            font-size: 13px;
			padding-bottom: 5px;
        }
        h3 {
            font-weight: 500;
            font-size: 17px;
            color: #43a047;
        }
        img {
            width: 35px;
            height: 35px;
        }
        display: flex;
        justify-content: space-between;
    }
}

.filter-date-paper {
	background-color: #fff;
    border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 5px 10px;
	cursor: pointer;
}


.selection-box-title {
	min-width: 200px;
	background-color: #fff;
	box-shadow: #ddd 0px 0px 10px;
	padding: 5px 15px;
	border-radius: 10px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	img {
		width: 18px;
		height: 18px;
		padding-right: 5px;
	}
	p {
		text-transform: capitalize;
	}
}

.color-red {
	color: red !important;
	font-weight: 400;
}

.color-black {
	color: black !important;
	font-weight: 400;
}


.container-hover {
    z-index: 500;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    min-width: 179px;
    .list-menu {
        padding: 7px 10px;
        cursor: pointer;
        margin: 5px 0;
    }
    .list-menu:hover {
        padding: 7px 10px;
        border-radius: 5px;
        background-color: #364153;
        cursor: pointer;
        margin: 5px 0;
        p {
            color: #fff;
        }
    }
    .list-menu-active {
        padding: 7px 10px;
        border-radius: 5px;
        background-color: #364153;
        cursor: pointer;
        margin: 5px 0;
        p {
            color: #fff;
        }
    }
}

.print-btn-pdfdownload {
	margin: 0 5px;
	display: flex;
	cursor: pointer;
	align-items: center;
	border: #43a047 1px solid;
	transition: all .2s ease-in;
	border-radius: 5px;
	padding: 5px 10px;
	p {
		font-size: 14px;
		color: #43a047;
		font-weight: 400;
	}
}
.print-btn-pdfdownload:hover{
	display: flex;
	cursor: pointer;
	align-items: center;
	background-color: #43a047;
	border-radius: 10px;
	padding: 5px 10px;
	p {
		font-size: 14px;
		color: #fff;
		font-weight: 400;
	}
}


.filter-component {
	border: 2px solid #ccc;
	border-radius: 10px;
	padding: 8px 10px;
	text-align: center;
	cursor: pointer;
}

.filter-content {
    background-color: #fff;
    box-shadow: #ccc 0px 0px 12px;
    padding: 15px;
    border-radius: 7px;
    margin-top: 5px;
	.divider-btn {
        border-top: #ccc solid 1px;
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
	.filter-checkbox {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		row-gap: 0.5rem;
		column-gap: 0.5rem;
		align-items: center;
		font-family: 'Roboto', sans-serif;
		text-transform: capitalize;
	}
}