@use '../breakpoints/breakpoints.scss';

.department-container {
    margin: 5px 0;
    .dep-item {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: #ddd 0 0 10px;
        padding: 1.2rem;
        .dep-item__name {
            h1 {
                font-size: 1.2rem;
                font-weight: 500;
                margin: 0;
                padding-bottom: 0.5rem;
            }
            p {
                font-size: 0.8rem;
                margin: 0;
                font-weight: 400;
            }
        }
        .dep-item__user {
            padding-top: 5px;
            h6 {
                font-size: 0.9rem;
                font-weight: 300;
                margin: 0;
            }
        }
        .dep-list__user {
            margin-top: 6px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .dep-list__user-item {
                border: #ddd 1px solid;
                padding: 0.5rem 1rem;
                border-radius: 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                p {
                    font-size: 0.8rem;
                    margin: 0;
                    font-weight: 500;
                }
            }
        }
        .dep-item__action {
            padding-top: 0.5rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            .items {
                display: flex;
                align-items: center;
            }
        }
    }
}

.create-department {
    width: 100%;
    min-height: 300px;
    h2 {
        text-align: left;
        font-size: 15px;
        color: #000;
        font-weight: 500;
        letter-spacing: 0.5px;
    }
}