@use '../breakpoints/breakpoints.scss';

.hover-info-product {
    padding: 2px 5px;
    h6 {
        color: #364153;
        font-size: 12px;
        font-weight: 400;
        padding: 3px 0;
    }
}
.selection-box {
    font-family: "Roboto", sans-serif;
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .selection-box-title {
        margin-top: 8px;
        min-width: 170px;
        background-color: #fff;
        box-shadow: #ddd 0px 0px 10px;
        padding: 7px 15px;
        border-radius: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        img {
            width: 18px;
            height: 18px;
            padding-right: 5px;
        }
    }
    p {
        color: #364153;
        text-transform: capitalize;
    }
}
.product-summary {
    // z-index: 500;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    .line-divider-bottom {
        border-bottom: #ddd solid 2px;
        margin: 5px 5px 10px;
    }
    .summary {
        margin-top: 10px;
        .summary-item {
            padding: 5px 10px;
            display: flex;
            justify-content: space-between;
            h6 {
                color: #364153;
                font-size: 16px;
                font-weight: 400;
                padding: 3px 0;
            }
            h5 {
                color: #364153;
                font-size: 16px;
                font-weight: bold;
                padding: 3px 0;
            }
            h4 {
                color: #364153;
                font-size: 17px;
                font-weight: bold;
                padding: 3px 0;
            }
            p {
                color: #364153;
                font-size: 12px;
                font-weight: 400;
                padding: 0;
            }
            span {
                color: #364153;
                font-size: 13px;
                font-weight: bold;
                padding: 0;
            }
        }
    }
}
.btn-customs-save {
    button {
        cursor: pointer;
        background-color: #43a047;
        color: #fff;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 10px;
        padding: 15px;
        margin-top: 15px;
        transition: all 0.3s ease;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        width: 100%;
        text-align: center;
        border: none;
    }
    :disabled {
        background-color: #ddd;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
    }

}
.btn-customs-save:hover {
    button {
        cursor: pointer;
        background-color: #308834;
        color: #fff;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 10px;
        padding: 15px;
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        width: 100%;
        text-align: center;
        border: none;
    }
    :disabled {
        background-color: #ddd;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
    }
}

.btn-customs-submit {
    button {
        cursor: pointer;
        background-color: #fff;
        border: #43a047 solid 1px;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 10px;
        padding: 15px;
        margin-top: 5px;
        transition: all 0.3s ease;
        width: 100%;
        text-align: center;
        color: #43a047;
        font-size: 17px;
        font-weight: 500;
    }
    :disabled {
        background-color: #ddd;
        border: #ddd solid 1px;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
    }
}

.btn-customs-submit:hover {
    button {
        cursor: pointer;
        background-color: rgb(236, 243, 232);
        border: #43a047 solid 1px;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 10px;
        padding: 15px;
        margin-top: 5px;
        transition: all 0.3s ease;
        width: 100%;
        text-align: center;
        color: #43a047;
        font-size: 17px;
        font-weight: 500;
    }
    :disabled {
        background-color: #ddd;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
    }
}

.btn-customs-add {
    button {
        cursor: pointer;
        background-color: #43a047;
        color: #fff;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 10px;
        padding: 8px;
        margin-top: 5px;
        transition: all 0.3s ease;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        width: 100%;
        text-align: center;
        border: none;
    }
    :disabled {
        background-color: #ddd;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
    }

}
.btn-customs-add:hover {
    button {
        cursor: pointer;
        background-color: #308834;
        color: #fff;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 10px;
        padding: 8px;
        margin-top: 5px;
        transition: all 0.3s ease;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        width: 100%;
        text-align: center;
        border: none;
    }
    :disabled {
        background-color: #ddd;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
    }
}

.input-name-template-error {
    margin: 15px 0;
    background-color: #fff;
    border-radius: 10px;
    color: #fff;
    border: #e84542 solid 1px;
    box-shadow: #e84542 0px 0px 5px;
    width: 100%;
    input {
        margin: 0 5px;
        padding: 20px 15px;
        width: 280px;
        border: none;
        outline: none;
        font-size: 15px;
        font-weight: 500;
        border-radius: 10px;
    }
    input:focus {
        outline: none;
    }
}

.input-name-template {
    margin: 15px 0;
    background-color: #fff;
    border-radius: 10px;
    color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    width: 100%;
    input {
        margin: 0 5px;
        padding: 20px 15px;
        width: 280px;
        border: none;
        outline: none;
        font-size: 15px;
        font-weight: 500;
        border-radius: 10px;
    }
    input:focus {
        outline: none;
    }
}



.product-cart {
    z-index: 500;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    .empty-list {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .line-divider-bottom {
        border-bottom: #ddd solid 2px;
        margin: 0 15px;
    }
    .cart-list {
        padding: 15px;
        .container-company {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .item-company {
                display: flex;
                align-items: center;
                padding-bottom: 5px;
                h2 {
                    text-transform: capitalize;
                    padding-left: 3px;
                    font-size: 17px;
                    font-weight: bold;
                    color: #364153;
                }
                p {
                    text-transform: capitalize;
                    padding-left: 3px;
                    font-size: 12px;
                    color: #364153;
                }
                .icon-store {
                    font-size: 40px;
                    color: #364153;
                }
            }
            .remove {
                cursor: pointer;
                border: 1px solid #e8505b;
                border-radius: 5px;
                margin-left: 10px;
                h6 {
                    color: #e8505b;
                    font-size: 15px;
                    font-weight: bold;
                    padding: 2px 7px !important;
                    
                }
            }
            .note {
                cursor: pointer;
                border: 1px solid #364153;
                border-radius: 5px;
                margin-left: 10px;
                h6 {
                    color: #364153;
                    font-size: 15px;
                    font-weight: 400;
                    padding: 2px 7px !important;
                    
                }
            }
        }
        .line-bottom {
            border-bottom: #000 solid 1px;
        }
        .item-product {
            display: flex;
            padding: 10px;
            font-size: 12px;
            box-shadow: #ddd 0px 0px 10px;
            margin: 10px 0;
            border-radius: 10px;
            img {
                width: 70px;
                height: 70px;
                border-radius: 10px;
                background-color: #fff;
            }
            
            .right-product {
                width: 100%;
                margin: 0 15px;
                .title-product {
                    h2 {
                        padding-bottom: 5px;
                        font-size: 18px;
                        font-weight: 400;
                        color: #364153;
                    }
                }
                .tax {
                    display: flex;
                    align-items: center;
                    padding: 5px 0;
                    p {
                        font-size: 12px;
                        font-weight: bold;
                        color: #364153;
                    }
                    
                }
                .bottom-content {
                    // padding: 5px;
                    // border: #000 solid 1px;
                    margin-bottom: 5px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    .total-tax {
                        padding: 5px 5px;
                        // background-color: #95a4b2;
                        border-radius: 5px;
                        height: fit-content;
                        margin-top: 3px;
                        h2 {
                            font-size: 13px;
                            font-weight: 300;
                            color: #1b4559;
                        }
                    }
                    img {
                        cursor: pointer;
                        padding-top: 2px;
                        width: 22px;
                        height: 22px;
                    }
                    .total-prices {
                        padding: 5px 5px;
                        // border: #43a047 1px solid;
                        border-radius: 5px;
                        height: fit-content;
                        h2 {
                            font-size: 16px;
                            font-weight: bold;
                            color: #1b4559;
                        }
                    }
                    .line-divide {
                        margin: 0 10px;
                        border-left: #ddd solid 1px;
                    }
                    .quantity-btn {
                        padding-top: 3px;
                        display: flex;
                        align-items: center;
                        img {
                            // cursor: pointer;
                            width: 20px;
                            height: 20px;
                        }
                        .input-qty {
                            input {
                                margin: 0 5px;
                                width: 50px;
                                text-align: center;
                                border-radius: 8px;
                                height: 20px;
                                border: #000 solid 2px;
                            }
                        }
                        p {
                            font-size: 14px;
                        }
    
                    }
                    .quantity-btn-error {
                        padding-top: 3px;
                        display: flex;
                        align-items: center;
                        img {
                            // cursor: not-allowed;
                            padding: 1px;
                            // background-color: #ddd;
                            width: 20px;
                            height: 20px;
                        }
                        .input-qty {
                            input {
                                margin: 0 5px;
                                width: 50px;
                                text-align: center;
                                border-radius: 8px;
                                height: 20px;
                                border: red solid 2px;
                            }
                            input*:focus {
                                outline: none;
                                border: red solid 2px;
                            }
                            *:focus {
                                outline: 0;
                            }
                        }
                    }
                    .remove {
                        cursor: pointer;
                        img {
                            width: 25px;
                            height: 25px;
                        }
                    }
                    .error-qty {
                        padding-right: 55px;
                        p {
                            color: red;
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                }
                @include breakpoints.media('<=phone') {
                    .bottom-content {
                        justify-content: flex-start;
                    }
                }
                @include breakpoints.media('>phone', '<=tablet') {
                    .bottom-content {
                        justify-content: flex-start;
                    }
                }
                .price {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .discount {
                        background-color: #e8505b;
                        border-radius: 5px;
                        padding: 2px 5px;
                        h3 {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                    .discount-amount {
                        background-color: #eaa24b;
                        border-radius: 5px;
                        padding: 2px 5px;
                        h3 {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                    .title-price {
                        h1 {
                            color: #364153;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                    .title-discount {
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                        margin-right: 5px;
                        h2 {
                            color: #364153;
                            font-size: 14px;
                            font-weight: 300;
                            padding-right: 5px;
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }
        
        .bottom-content-vendor {
            //  border: #000 solid 1px;
            box-shadow: #43a047 0px 0px 3px;
            border-radius: 10px;
            padding: 15px;
            display: flex;
            justify-content: flex-end;
            align-self: center;
            flex-wrap: wrap;
            .total-prices {
                padding: 5px 10px;
                // border: #43a047 1px solid;
                // background-color: #43a047;
                border-radius: 5px;
                height: fit-content;
                h2 {
                    font-size: 16px;
                    font-weight: bold;
                    color: #43a047;
                }
            }
            .total-dp {
                padding: 5px;
                h2 {
                    font-size: 15px;
                    font-weight: 400;
                    color: #364153;
                }
            }
            .total-tax {
                padding: 5px 10px;
                // border: #95a4b2 1px solid;
                // background-color: #95a4b2;
                border-radius: 5px;
                height: fit-content;
                h2 {
                    font-size: 15px;
                    font-weight: 500;
                    color: #364153;
                }
            }
            .line-divide {
                 margin: 0 15px;
                 border-left: #ddd solid 1px;
            }
        }
        @include breakpoints.media('<=phone') {
            .bottom-content-vendor {
                justify-content: center;
                .line-divide {
                    display: none;
                }
            }
        }
        @include breakpoints.media('>phone', '<=tablet') {
            .bottom-content-vendor {
                justify-content: center;
                .line-divide {
                    display: none;
                }
            }
        }
    }
}

.detail-purchase {
    margin-top: 5px;
    margin-bottom: 15px;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: #ddd 0px 0px 10px;
    .top-div {
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .left {
            display: flex;
            flex-wrap: wrap;
            // align-items: center;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            
            h5 {
                font-size: 15px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
                padding-top: 2px;
            }
            h4 {
                font-size: 17px;
                color: #43a047;
                font-weight: bold;
                padding-bottom: 5px;
            }
            h6 {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                padding-bottom: 5px;
            }
            .line-right {
                border-right: #ddd 1px solid;
                margin: 0 10px;
            }
        }
        .right {
            display: flex;
            flex-direction: row;
            align-items: center;
            .status {
                padding: 3px 10px;
                border-radius: 5px;
                h6 {
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                    margin: 0;
                }
            }
            img {
                padding-top: 3px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
    
}

.box-detail-address {
    margin: 10px 0; 
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: #ddd 0px 0px 10px;
    h5 {
        font-size: 13px;
        color: #000;
        font-weight: 500;
    }
    p {
        font-size: 12px;
        color: #000;
        font-weight: 400;
        margin-top: 5px;
        text-transform: capitalize;
    }
}

.box-detail-btn {
    display: flex;
    justify-content: space-between;
    margin: 10px 0; 
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: #ddd 0px 0px 10px;
    .btn-pr-submit {
        margin: 0 5px;
        width: 100%;
        button {
            cursor: pointer;
            background-color: #fff;
            border: #43a047 solid 1px;
            box-shadow: #ddd 0px 0px 10px;
            border-radius: 5px;
            padding: 8px 20px;
            transition: all 0.3s ease;
            width: 100%;
            text-align: center;
            color: #43a047;
            font-size: 14px;
            font-weight: 500;
        }
        :disabled {
            background-color: #ddd;
            border: #ddd solid 1px;
            color: rgb(168, 165, 165);
            cursor: not-allowed;
        }
    }
    .btn-pr-submit:hover {
        margin: 0 5px;
        width: 100%;
        button {
            cursor: pointer;
            background-color: #43a047;
            border: #fff solid 1px;
            box-shadow: #ddd 0px 0px 10px;
            border-radius: 5px;
            padding: 8px 20px;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
        }
        :disabled {
            background-color: #ddd;
            color: rgb(168, 165, 165);
            cursor: not-allowed;
        }
    }
    .btn-pr-reject {
        margin: 0 5px;
        width: 100%;
        button {
            cursor: pointer;
            background-color: #fff;
            border: #e8505b solid 1px;
            box-shadow: #ddd 0px 0px 10px;
            border-radius: 5px;
            padding: 8px 20px;
            transition: all 0.3s ease;
            width: 100%;
            text-align: center;
            color: #e8505b;
            font-size: 14px;
            font-weight: 500;
        }
        :disabled {
            background-color: #ddd;
            border: #ddd solid 1px;
            color: rgb(168, 165, 165);
            cursor: not-allowed;
        }
    }
    .btn-pr-reject:hover {
        margin: 0 5px;
        width: 100%;
        button {
            cursor: pointer;
            background-color: #e8505b;
            border: #fff solid 1px;
            box-shadow: #ddd 0px 0px 10px;
            border-radius: 5px;
            padding: 8px 20px;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
        }
        :disabled {
            background-color: #ddd;
            color: rgb(168, 165, 165);
            cursor: not-allowed;
        }
    }
}

.cart-list-detail {
    padding: 15px;
    .container-company {
        display: flex;
        justify-content: space-between;
        .item-company {
            display: flex;
            align-items: center;
            h2 {
                text-transform: capitalize;
                padding-left: 3px;
                font-size: 14px;
                font-weight: bold;
                color: #364153;
            }
            p {
                text-transform: capitalize;
                padding-left: 3px;
                font-size: 11px;
                color: #364153;
            }
            .icon-store {
                font-size: 30px;
                color: #364153;
            }
        }
        .view-note {
            p {
                text-transform: capitalize;
                padding-left: 3px;
                font-size: 14px;
                color: #364153;
                cursor: pointer;
            }
        }
    }
    .line-bottom {
        border-bottom: #000 solid 1px;
    }
    .item-product {
        display: flex;
        padding: 10px;
        font-size: 12px;
        box-shadow: #ddd 0px 0px 10px;
        margin: 10px 0;
        border-radius: 10px;
        img {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            background-color: #fff;
        }
        
        .right-product {
            width: 100%;
            margin: 0 15px;
            .title-product {
                h2 {
                    padding-bottom: 5px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #364153;
                }
            }
            .tax {
                display: flex;
                align-items: center;
                padding: 5px 0;
                p {
                    font-size: 12px;
                    font-weight: bold;
                    color: #364153;
                }
                
            }
            .bottom-content {
                // padding: 5px;
                // border: #000 solid 1px;
                margin-bottom: 5px;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                .total-tax {
                    padding: 5px 5px;
                    // background-color: #95a4b2;
                    border-radius: 5px;
                    height: fit-content;
                    margin-top: 1px;
                    h2 {
                        font-size: 13px;
                        font-weight: 300;
                        color: #1b4559;
                    }
                }
                img {
                    cursor: pointer;
                    padding-top: 2px;
                    width: 22px;
                    height: 22px;
                }
                .total-prices {
                    padding: 5px 5px;
                    // border: #43a047 1px solid;
                    border-radius: 5px;
                    height: fit-content;
                    h2 {
                        font-size: 15px;
                        font-weight: bold;
                        color: #1b4559;
                    }
                }
                .line-divide {
                    margin: 0 10px;
                    border-left: #ddd solid 1px;
                }
            }
            .price {
                display: flex;
                align-items: center;
                .discount {
                    background-color: #e8505b;
                    border-radius: 5px;
                    padding: 2px 5px;
                    h3 {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 300;
                    }
                }
                .discount-amount {
                    background-color: #eaa24b;
                    border-radius: 5px;
                    padding: 2px 5px;
                    h3 {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 300;
                    }
                }
                .title-price {
                    h1 {
                        color: #364153;
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
                .title-discount {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    margin-right: 5px;
                    h2 {
                        color: #364153;
                        font-size: 13px;
                        font-weight: 300;
                        padding-right: 5px;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
    .bottom-content-vendor {
        box-shadow: #43a047 0px 0px 3px;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        align-self: center;
        flex-wrap: wrap;
        .total-prices {
            padding: 5px 10px;
            border-radius: 5px;
            height: fit-content;
            h2 {
                font-size: 15px;
                font-weight: bold;
                color: #43a047;
            }
        }
        .total-dp {
            padding: 5px;
            h2 {
                font-size: 14px;
                font-weight: 400;
                color: #364153;
            }
        }
        .total-tax {
            padding: 5px 10px;
            border-radius: 5px;
            height: fit-content;
            h2 {
                font-size: 14px;
                font-weight: 500;
                color: #364153;
            }
        }
        .line-divide {
             margin: 0 15px;
             border-left: #ddd solid 1px;
        }
    }
}

.container-hover {
    z-index: 500;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    min-width: 179px;
    .list-menu {
        padding: 7px 10px;
        cursor: pointer;
        margin: 5px 0;
    }
    .list-menu:hover {
        padding: 7px 10px;
        border-radius: 5px;
        background-color: #364153;
        cursor: pointer;
        margin: 5px 0;
        p {
            color: #fff;
        }
    }
    .list-menu-active {
        padding: 7px 10px;
        border-radius: 5px;
        background-color: #364153;
        cursor: pointer;
        margin: 5px 0;
        p {
            color: #fff;
        }
    }
}

.hover-container {
    position: relative;
    .container-hover-pr {
        position: absolute;
        width: 98%;
        background-color: #fff;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 6px;
        padding: 15px;
        .filter {
            width: 100%;
            margin: 10px;
            background-color: #fff;
            box-shadow: #ddd 0px 0px 10px;
            border-radius: 6px;
            padding: 15px;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            p {
                font-size: 17px;
                padding-bottom: 2px;
            }
            span {
                // color: #43a047;
                text-transform: capitalize;
            }
            .sort {
                display: flex;
                align-items: center;
                h6 {
                    font-size: 16px;
                    color: #43a047;
                    padding-right: 15px;
                    padding-left: 10px;
                }
                select {
                    border: 1px solid #43a047;
                    padding: 5px 10px;
                    border-radius: 5px;
                    outline: none;
                    background-color: #fff;
                    font-size: 16px;
                    color: #43a047;
                    cursor: pointer;
                }
                .filter-product {
                    padding-right: 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    border: 1px solid #43a047;
                    padding: 2px 10px;
                    border-radius: 5px;
                    p {
                        padding-left: 5px;
                        color: #43a047;
                    }
                }
            }
        }
        .container-content {
            border-radius: 6px;
            margin: 15px;
            .image-card-box-pr {
                width: 100%;
                background-color: #fff;
                border-radius: 10px;
                min-height: 220px;
                box-shadow: #ddd 0px 0px 10px;
                .zoom-effect-container {
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    .image-card-new {
                        height: 150px;
                        width: auto;
                        cursor: pointer;

                    }
                    
                    .next-arrow-slider {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 100;
                        cursor: pointer;
                        background: rgba(255, 255, 255, 0.651);
                        border-radius:50%;
                        padding-top: 2px;
                        padding-bottom: 1px;
                        padding-right: 2px;
                        padding-left: 4px;
                        color: #000;
                        cursor: pointer;
                        img {
                            width: 15px;
                            height: 10px;
                        }
                    }
                    .prev-arrow-slider {
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 100;
                        cursor: pointer;
                        background: rgba(255, 254, 254, 0.651);
                        border-radius:50%;
                        padding-top: 2px;
                        padding-bottom: 1px;
                        padding-right: 4px;
                        padding-left: 2px;
                        color: #ddd;
                        img {
                            width: 15px;
                            height: 10px;
                        }
                    }
                    .dot-mapping {
                        display: flex;
                        position: absolute;
                        top: 90%;
                        left: 50%;
                        transform: translateY(-90%);
                        transform: translateX(-50%);
                        z-index: 100;
                    }
                    .dot-slider {
                        height: 7px;
                        width: 7px;
                        background-color: rgb(184, 180, 180);
                        border-radius: 50%;
                        display: inline-block;
                        cursor: pointer;
                        margin-left: 2px;
                        margin-right: 2px;
                    }
                    .dot-slider-active {
                        height: 8px;
                        width: 8px;
                        background-color: rgb(48, 247, 21);
                        border-radius: 50%;
                        display: inline-block;
                        cursor: pointer;
                        margin-left: 2px;
                        margin-right: 2px;
                    }
                }
                .title-name {
                    margin-top: 0px;
                    margin-left: 15px;
                    margin-right: 15px;
                    min-height: 30px;
                    h1 {
                        text-transform: capitalize;
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0.1px;
                    }
                }
                .title-brand {
                    margin-top: 7px;
                    margin-left: 5px;
                    margin-right: 15px;
                    margin-bottom: 0px;
                    width: fit-content;
                    border-radius: 5px;
                    // border: #3bb001 1px solid;
                    padding: 4px 10px;
                    h4 {
                        text-transform: capitalize;
                        width: fit-content;
                        color: #3bb001;
                        font-size: 11px;
                        font-weight: 300;
                    }
                }
                .title-price {
                    margin-top: 5px;
                    margin-left: 15px;
                    margin-right: 15px;
                    h1 {
                        text-transform: capitalize;
                        color: #e64542;
                        font-size: 15px;
                        font-weight: bold;
                        letter-spacing: 0.1px;
                    }
                }
                .title-discount {
                    display: flex;
                    align-items: center;
                    margin-top: 0px;
                    margin-left: 15px;
                    margin-right: 15px;
                    h2 {
                        text-transform: capitalize;
                        color: #afc0c9;
                        font-size: 11px;
                        font-weight: 300;
                        letter-spacing: 0.1px;
                        padding-right: 5px;
                        text-decoration: line-through;
                    }
                    .discount {
                        background-color: #e8505b;
                        border-radius: 5px;
                        padding: 2px 5px;
                        margin-right: 7px;
                        h3 {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                    .info-box {
                        padding-top: 3px;
                        // margin: 0 7px;
                        cursor: pointer;
                        img {
                            width: 22px;
                        }
                        p {
                            font-size: 10px;
                        }
                    }
                }
                .title-discount-amount {
                    display: flex;
                    align-items: center;
                    margin-top: 0px;
                    margin-left: 15px;
                    margin-right: 15px;
                    h2 {
                        text-transform: capitalize;
                        color: #afc0c9;
                        font-size: 11px;
                        font-weight: 300;
                        letter-spacing: 0.1px;
                        padding-right: 5px;
                        text-decoration: line-through;
                    }
                    .discount {
                        background-color: #ff9f43;
                        border-radius: 5px;
                        padding: 2px 5px;
                        margin-right: 7px;
                        h3 {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                    .info-box {
                        padding-top: 3px;
                        // margin: 0 7px;
                        cursor: pointer;
                        img {
                            width: 22px;
                        }
                        p {
                            font-size: 10px;
                        }
                    }
                }
                .title-discount-special {
                    display: flex;
                    align-items: center;
                    margin-top: 0px;
                    margin-left: 15px;
                    margin-right: 15px;
                    h2 {
                        text-transform: capitalize;
                        color: #afc0c9;
                        font-size: 11px;
                        font-weight: 300;
                        letter-spacing: 0.1px;
                        padding-right: 5px;
                        text-decoration: line-through;
                    }
                    .discount {
                        background-color: #26b99a;
                        border-radius: 5px;
                        padding: 2px 5px;
                        margin-right: 7px;
                        h3 {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                    .info-box {
                        padding-top: 3px;
                        // margin: 0 7px;
                        cursor: pointer;
                        img {
                            width: 22px;
                        }
                        p {
                            font-size: 10px;
                        }
                    }
                }
                .box-qty-btn {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding:10px;
                    .add-qty {
                        align-items: center;
                        padding: 8px 5px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        border-radius: 10px;
                        background-color: #fff;
                        box-shadow: #ddd 0px 0px 8px;
                        // border: #2d7230 solid 1px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        img {
                            width: 20px;
                            height: 20px;
                        }
                        input {
                            margin: 0 8px;
                            width: 50px;
                            text-align: center;
                            border-radius: 8px;
                            height: 20px;
                            // border: none;
                        }
                    }
                    .add-qty-error {
                        box-shadow: red 0px 0px 5px;
                    } 
                    .add-btn-box {
                        margin-top: 5px;
                        text-align: center;
                        cursor: pointer;
                        background-color: #43a047;
                        border-radius: 10px;
                        box-shadow: #ddd 0px 0px 10px;
                        padding: 3px 10px;
                        p {
                            color: #fff;
                        }
                        margin-bottom: 5px;
                    }
                    .add-qty-box {
                        margin: 0 2px;
                        cursor: pointer;
                    }
                    .error-qty {
                        text-align: center;
                        p {
                            font-size: 12px;
                            color: red;
                        }
                    }
                    
                }
                .button-cards { 
                    // margin: 10px;
                    display: flex;
                    flex-direction: column;
                    padding: 15px;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    .pr {
                        cursor: pointer;
                        margin-bottom: 5px;
                        font-size: 14px;
                        justify-content: flex-start;
                        background-color: #43a047;
                        border-radius: 10px;
                        text-align: center;
                        padding: 8px;
                        color: #fff;
                        -moz-transition: all .2s ease-in;
                        -o-transition: all .2s ease-in;
                        -webkit-transition: all .2s ease-in;
                        transition: all .2s ease-in;
                    }
                    .pr:hover {
                        background-color: #2d7230;
                    }
                    .template {
                        cursor: pointer;
                        font-size: 15px;
                        justify-content: flex-end;
                        border: #43a047 2px solid;
                        padding: 8px 10px;
                        border-radius: 10px;
                        text-align: center;
                        color: #43a047;
                        -moz-transition: all .2s ease-in;
                        -o-transition: all .2s ease-in;
                        -webkit-transition: all .2s ease-in;
                        transition: all .2s ease-in;
                    }
                    .template:hover {
                        background-color: #f1eeee;
                        color: #398f3d;
                        border: #398f3d 2px solid;
                    }
                }
            }
    
        }
    }
}


// purchase card item

// .item-card-pr {
//     max-height: calc(100vh - 350px);
//     overflow-x: auto;
// }

.container-search-purchases {
    background-color: #fff;
    border: #ddd solid 1px;
    box-shadow: #ddd 0px 0px 15px;
    border-radius: 10px;
    // height: 150px;
    // position: fixed;
    padding: 15px;
    .status {
        display: flex; 
        flex-direction: row;
        align-items: center;
        padding-top: 15px;
        h5 {
            font-size: 15px;
            font-weight: 500;
            color: #000;
            text-transform: capitalize;
            padding-right: 10px;
        }
        .list-status {
            display: flex;
            justify-content: flex-start;
            column-gap: 1px;
            .status-box {
                padding-bottom: 5px;
                // background-color: #fff;
                border: #ddd solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                padding: 8px 17px;
                border-radius: 10px;
                cursor: pointer;
                h6 {
                    font-size: 15px;
                    font-weight: 400;
                    color: #000;
                    text-transform: capitalize;
                    margin: 0;
                }
            }
            .active {
                background-color: #43a047;
                h6 {
                    color: #fff;
                    margin: 0;
                }
            }
        }
    }
    @include breakpoints.media('<=tablet') {
        .status {
            display: flex; 
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-top: 15px;
            h5 {
                padding-bottom: 10px;
            }
            .list-status {
                display: flex;
                row-gap: 0.5rem;
                column-gap: 0.5rem;
                flex-wrap: wrap;
                .status-box {
                    padding-bottom: 5px;
                    // background-color: #fff;
                    border: #ddd solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 12px;
                    border-radius: 10px;
                    cursor: pointer;
                    h6 {
                        font-size: 13px;
                        font-weight: 400;
                        color: #000;
                        text-transform: capitalize;
                        margin: 0;
                    }
                }
                .active {
                    background-color: #43a047;
                    h6 {
                        color: #fff;
                        margin: 0;
                    }
                }
            }
        }
    }
    .search-box {
        display: flex;
        align-items: center;;
        background-color: #fff;
        border: #ddd solid 2px;
        border-radius: 10px;
        padding: 0px 10px;
        input {
            background-color: #fff;
            padding: 10px 10px;
            width: 100%;
            border: none;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 300;
            color: #000;
            &:focus {
                outline: none;
            }
        }
        #select-style-addresss {
            width: 150px;
            border-style: none !important;
        }
        .notyping {
            display: flex;
            align-items: center;
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        .typing {
            display: flex;
            align-items: center;
            .removeimg {
                width: 17px;
                height: 17px;
                cursor: pointer;
            }
            .divider-line {
                width: 1px;
                height: 20px;
                background-color: #000;
                margin: 0 15px;
            }
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        
    }
    .recently-view {
        padding: 16px 10px;
        display: flex;
        border-radius: 10px;
        cursor: pointer;
        justify-content: center;
        background-color: #fff;
        border: #ddd solid 2px;
        img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }
    
}

.container-item-purchase {
    margin: 15px 3px;
    padding: 20px;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    // height: 150px;
    // width: 100%;
    .top-div {
        padding: 5px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .left {
            display: flex;
            flex-wrap: wrap;
            row-gap: 0.3rem;
            column-gap: 0.3rem;
            // align-items: center;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            h6 {
                font-size: 17px;
                color: #023047;
                font-weight: 500;
                padding-bottom: 5px;
            }
            h5 {
                font-size: 15px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                padding-bottom: 5px;
            }
            .line-right {
                border-right: #ddd 1px solid;
                margin: 0 5px;
            }
            .edited-icon {
                cursor: pointer;
                img {
                    width: 20px;
                    height: 20px;
                }
                padding-top: 2px;
            }
            .edited-pr {
                width: fit-content;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: 5px;
                img {
                    width: 20px;
                    height: 20px;
                }
                input {
                    padding: 5px 10px;
                    border-radius: 5px;
                    border-width: 2px;
                    min-width: 230px;
                    outline: none;
                }
                input:focus {
                    outline: none;
                    border-color: #43a047;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            padding-right: 5px;
            .status {
                padding: 3px 10px;
                border-radius: 5px;
                h6 {
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                }
            }
            img {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
    .center-div {
        padding: 5px 0;
        align-items: center;
        .left-box {
            h2 {
                font-size: 14px;
                color: #000;
                font-weight: 500;;
                padding-left: 5px;
            }
            .vendor-list {
                padding: 7px 0;
                display: flex;
                flex-wrap: wrap;
                .box-vendor {
                    border: #ddd 1px solid;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 10px;
                    margin: 5px;
                    border-radius: 5px;
                    h3 {
                        text-transform: capitalize;
                        font-size: 15px;
                        color: #000;
                        font-weight: 400;
                    }
                }
                
            }
        }
        .total-prices {
            border-left: #ddd solid 1px;
            margin: 3px 0;
            padding: 15px;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 300;
                padding-bottom: 5px;
            }
            h3 {
                font-size: 17px;
                color: #000;
                font-weight: bold;
                margin: 0;
            }
        }
    }
    .bottom-div {
        margin-top: 5px;
        padding: 5px 10px;
        display: flex;
        justify-content: flex-end;        
        align-items: center;
        flex-wrap: wrap;
        .btn-pr-submit {
            margin: 5px;
            button {
                cursor: pointer;
                background-color: #fff;
                border: #43a047 solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 8px 20px;
                transition: all 0.3s ease;
                width: 100%;
                text-align: center;
                color: #43a047;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                border: #ddd solid 1px;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-submit:hover {
            margin: 0 5px;
            button {
                cursor: pointer;
                background-color: #43a047;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 8px 20px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-reject {
            margin: 0 5px;
            button {
                cursor: pointer;
                background-color: #fff;
                border: #e8505b solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 8px 20px;
                transition: all 0.3s ease;
                width: 100%;
                text-align: center;
                color: #e8505b;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                border: #ddd solid 1px;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-reject:hover {
            margin: 0 5px;
            button {
                cursor: pointer;
                background-color: #e8505b;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 8px 20px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .view-btn {
            cursor: pointer;
            p {
                font-size: 14px;
                color: #43a047;
                font-weight: 500;
                padding: 0 5px;
            }
        }
        .view-btn-pdf {
            cursor: pointer;
            p {
                font-size: 14px;
                color: #023047;
                font-weight: 500;
                padding: 0 5px;
            }
        }
        .line-divider {
            width: 1px;
            height: 20px;
            margin: 6px 10px;
            background: #ddd;
        }
    }
}

.history-status {
    max-height: 400px;
    overflow-x: auto;
    .content {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: #ddd 0px 0px 10px;
        margin: 5px 5px;
        padding: 10px;
        .top {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            h5 {
                min-width: 70px;
                font-size: 15px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
                font-style: normal;
            }
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                padding-bottom: 5px;
            }
        }
        h6 {
            font-size: 14px;
            color: #000;
            font-weight: 400;
            padding-bottom: 5px;
        }
        h5 {
            min-width: 70px;
            font-size: 14px;
            color: #000;
            font-weight: 400;
            padding-bottom: 5px;
        }
    }
    h4 {
        font-size: 16px;
        color: #000;
        font-weight: 400;
        padding-bottom: 5px;
    }
}

.proofpayment {
    display: flex;
    justify-content: center;
}